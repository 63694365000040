<template>
  <div class="new-project-modal">
    <div class="new-project-content" :class="{ regie: isFromRegie }">
      <div v-if="isFromRegie" class="new-project-modal-wrapper">
        <div class="server-error" v-if="getProjectsCreateError">
          <div v-if="getProjectsCreateError" class="error">
            <ul v-if="Array.isArray(getProjectsCreateError)">
              <li
                v-for="(e, index) in getProjectsCreateError"
                :key="'get-projects-create-error-' + index"
              >
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getProjectsCreateError }}</span>
          </div>
        </div>
        <!-- <RegieForm
        :enableFastForm="fastFormEnabled"
        @created="handleRegieFormCreated"
        :currentType="currentType"
      /> -->
      </div>
      <div v-else class="new-project-modal-wrapper">
        <div class="title-modal border-bottom-solid">
          <span class="font-sz-14 bold-700 font-text color-black">
            {{
              $i18n.locale === 'fr'
                ? 'Ajouter un nouveau projet'
                : 'Add a new project'
            }}
          </span>
          <div>
            <v-btn icon fab small @click="handleClose"
              ><v-icon>mdi-close </v-icon></v-btn
            >
          </div>
        </div>
        <div class="form-new-project">
          <v-form ref="addNewProjet">
            <div class="description-bloc mb-2">
              {{ $i18n.locale === 'fr' ? 'Type de projet' : 'Project type' }}
            </div>
            <div class="description-bloc">
              <v-select
                :clearable="true"
                v-model="projectToAdd.type"
                :items="visibilityProjectsTypes"
                required
                :loading="getProjectsTypesLoading"
                @change="changePipelineSelonType(projectToAdd.type)"
                validate-on-blur
                :rules="[
                  v => !!v || $t('msgObligMsg', { msg: $t('typeProjet') })
                ]"
                class="msg-error"
                item-text="name"
                item-value="id"
                dense
                outlined
                color="#5C2DD3"
                item-color="#5C2DD3"
                label="Type"
                :persistent-placeholder="true"
                :placeholder="$t('selectionner')"
                :no-data-text="
                  $t('noDataOptionMsg', { option: $t('typeProjet') })
                "
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
              >
              </v-select>
            </div>
            <!-- Project Assign  -->
            <div class="description-bloc">
              <v-autocomplete
                :clearable="true"
                color="#5C2DD3"
                :placeholder="$t('assigned_to')"
                item-color="#5C2DD3"
                v-model="projectToAdd.project_owner"
                :items="allUsers"
                :persistent-placeholder="true"
                :label="$t('assigned_to')"
                item-text="full_name"
                dense
                item-value="id"
                outlined
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                required
                validate-on-blur
                :rules="[v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')]"
              >
              </v-autocomplete>
            </div>

            <!------------- Contact  ------------>
            <div class="description-bloc margin-div mb-2">
              {{
                $i18n.locale === 'fr' ? 'Contact du projet' : 'Project contact'
              }}
            </div>
            <div class="description-bloc">
              <v-radio-group v-model="contactState" class="radio-dynamique">
                <v-radio
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Contact existant'
                      : 'Existing contact'
                  "
                  value="old"
                  color="#5C2DD3"
                  class="label-radio"
                ></v-radio>
                <v-radio
                  :label="
                    $i18n.locale === 'fr' ? 'Nouveau contact' : 'New contact'
                  "
                  value="new"
                  color="#5C2DD3"
                  class="label-radio"
                ></v-radio>
              </v-radio-group>
            </div>
            <v-divider class="mb-2"></v-divider>
            <!--------------  Detail nv contact  ------------>
            <div v-if="contactState == 'new'">
              <div class="description-bloc">
                {{
                  $i18n.locale === 'fr'
                    ? 'Détails du nouveau contact'
                    : 'New contact details'
                }}
              </div>
              <div class="description-bloc">
                <v-row>
                  <v-col>
                    <v-text-field
                      type="text"
                      :label="$t('name')"
                      :persistent-placeholder="true"
                      outlined
                      required
                      validate-on-blur
                      :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                      class="msg-error"
                      dense
                      color="#5C2DD3"
                      v-model="contactToAdd.last_name"
                    ></v-text-field
                  ></v-col>
                  <v-col>
                    <v-text-field
                      type="text"
                      :label="$t('first_name')"
                      :persistent-placeholder="true"
                      outlined
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('msgObligMsg', { msg: $t('first_name') })
                      ]"
                      class="msg-error"
                      dense
                      color="#5C2DD3"
                      v-model="contactToAdd.first_name"
                    ></v-text-field
                  ></v-col>
                </v-row>
              </div>
            </div>
            <!----------------Existant contact  ------------>
            <div v-else>
              <div class="description-bloc">
                {{
                  $i18n.locale === 'fr'
                    ? 'Sélectionnez le contact existant'
                    : 'Select existing contact'
                }}
              </div>
              <div class="description-bloc">
                <v-autocomplete
                  :clearable="true"
                  v-model="selectedContact"
                  :items="getContactsList"
                  :loading="getContactsProcessing"
                  @update:search-input="asyncSearchContact"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      $t('msgObligMsg', {
                        msg:
                          $i18n.locale === 'fr'
                            ? 'Contact existant'
                            : 'Existing contact'
                      })
                  ]"
                  class="msg-error"
                  return-object
                  item-text="full_name"
                  item-value="id"
                  dense
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  label="Contact"
                  :persistent-placeholder="true"
                  :placeholder="$t('selectionner')"
                  :no-data-text="$t('noDataOptionMsg', { option: 'contact' })"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </div>
            </div>
          </v-form>
          <!-- MESSAGE AND LOADING -->
          <div class="actions-msg-btn-modal">
            <div v-if="getProjectsCreateError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsCreateError)">
                <li
                  v-for="(e, index) in getProjectsCreateError"
                  :key="'get-projects-create-error-' + index"
                >
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsCreateError }} </span>
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li
                  v-for="(e, index) in error"
                  :key="'get-projects-create-error-' + index"
                >
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }} </span>
            </div>
          </div>
        </div>

        <!-- ACTIONS BTN -->
        <div class="action-new-projet">
          <v-btn color="#5C2DD3" dark :loading="loading" @click="addProject">
            {{ $i18n.locale === 'fr' ? 'Ajouter le projet' : 'Add the project'
            }}<v-icon class="ml-1">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            text
            class="ml-3"
            @click="handleClose"
            variant="outline-secondary"
          >
            {{ $t('btnCancel') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'new-project-modal',
  components: {
    // RegieForm: () => import('@/views/Projects/modals/RegieForm.vue')
  },
  props: {
    currentType: {
      required: true
    }
  },
  data() {
    return {
      projectToAdd: { type: null, project_owner: null },
      contactToAdd: {},
      selectedContact: null,
      contactRequiredError: false,
      contactState: 'old',
      loading: false,
      error: null,
      premierStage: null,
      premierSubstage: null
    }
  },
  methods: {
    ...mapActions([
      'createProject',
      'searchContacts',
      'resetSearchContacts',
      'resetProjectError',
      'fetchEtapes',
      'fetchEtapeStage',
      'fetchEtapeSubstage',
      'getAllUsers'
    ]),

    // Handle Close Modal //
    handleClose() {
      this.error = null
      this.$emit('close')
      this.$refs.addNewProjet.resetValidation()
      this.resetProjectError()
    },

    async changePipelineSelonType(type_id) {
      if (type_id) {
        await this.fetchEtapes({ type_id: type_id, isNotLoading: true }).then(
          async () => {
            let etapeAvecSubStage = null
            let subStagesRemplis = null

            for (const etape of this.getEtapes) {
              subStagesRemplis = [] // Réinitialiser la liste des sub-stages remplis pour chaque nouvelle étape
              for (const stage of etape.stages) {
                await this.fetchEtapeSubstage({
                  cstage_id: stage.id,
                  stage: stage
                }) // Appel de l'API pour remplir les sub-stages de chaque stage de chaque étape

                if (stage.subStages.length > 0) {
                  etapeAvecSubStage = stage
                  subStagesRemplis = stage.subStages // Stocker les sub-stages remplis du stage actuel
                  break // Sortir de la boucle si les sub-stages de ce stage ne sont pas vides
                }
              }

              if (etapeAvecSubStage) {
                break // Sortir de la boucle externe si des sub-stages non vides sont trouvés dans une étape
              }
            }
            this.premierStage = etapeAvecSubStage
            this.premierSubstage =
              subStagesRemplis && subStagesRemplis.length
                ? subStagesRemplis[0]
                : subStagesRemplis
          }
        )
      }
    },
    async addProject() {
      this.resetProjectError()
      this.error = null
      if (this.$refs.addNewProjet.validate()) {
        this.loading = true
        this.contactRequiredError = false
        if (this.contactState == 'old' && !this.selectedContact) {
          this.contactRequiredError = true
          return
        }
        const data = {}
        data.dtype_id = this.projectToAdd.type
        data.project_owner = this.projectToAdd.project_owner
        if (this.getEtapes && this.getEtapes.length) {
          if (
            this.getEtapes[0].type.some(element => element.id == data.dtype_id)
          ) {
            data.step_id = this.getEtapes[0].id
            if (this.premierStage) {
              data.cstage_id = this.premierStage.id
            }
            if (this.premierSubstage) {
              data.csubstage_id = this.premierSubstage.id
            }
          }
        } else {
          this.loading = false
          this.error = 'Vous devez créer un pipeline pour ce type de projet'
          return
        }
        if (this.contactState == 'old') {
          data.contact_id = this.selectedContact.id
          data.title = this.selectedContact.full_name
        } else {
          data.contact = this.contactToAdd
          data.title = `${this.contactToAdd.first_name} ${this.contactToAdd.last_name}`
        }
        const response = await this.createProject({
          currentType: this.currentType,
          data
        })
        if (response) {
          this.resetNewProject()
          this.$emit('created', this.getProjectsLastCreated)
        }
        this.loading = false
      }
    },

    handleRegieFormCreated() {
      this.$emit('created', this.getProjectsLastCreated)
    },

    resetNewProject() {
      this.projectToAdd = { type: null, project_owner: null }
      this.contactToAdd = {}
      this.selectedContact = null
      this.contactRequiredError = false
      this.contactState = 'old'
      this.resetSearchContacts()
    },
    asyncSearchContact: debounce(function(query) {
      if (query && query.length > 1) {
        this.searchContacts(query)
      }
    }, 400)
  },
  computed: {
    ...mapGetters([
      'getEtapes',
      'getProjectsCreateError',
      'getProjectsLastCreated',
      'getContactsList',
      'getContactsProcessing',
      'getProjectsProcessing',
      'getProjectsTypes',
      'currentUser',
      'getProjectsTypesLoading',
      'allUsers'
    ]),
    fastFormEnabled: function() {
      if (this.currentUser.details && this.currentUser.details.see_form_fast) {
        return true
      }
      return false
    },
    isFromRegie: function() {
      if (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type == 'regie'))
      ) {
        return true
      }
      return false
    },
    visibilityProjectsTypes: function() {
      return this.getProjectsTypes
    }
  },
  mounted() {
    this.getAllUsers()
  }
}
</script>

<style lang="scss" scoped>
.new-project-modal {
  position: fixed;
  height: calc(100% - 0px);
  transition: width 0.1s;
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;
  .new-project-content {
    position: fixed;
    width: 500px;
    right: 0px;
    height: 100%;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    font-size: 13px;
    color: #5e5e5e;
  }
  .close {
    position: absolute;
    font-size: 20px;
    top: 10px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
  }

  .new-project-modal-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    // padding: 30px;
    font-size: 13px;
    color: #5e5e5e;
    .title-modal {
      padding: 20px;
      background-color: #f6f6f6;
    }
    .form-new-project {
      padding: 20px;
      position: relative;
      width: 100%;
      height: calc(100vh - 140px);
    }
    .action-new-projet {
      float: right;
    }
    .action-new-projet,
    .actions-msg-btn-modal {
      padding: 20px;
    }
    .margin-div {
      margin-top: -14px;
    }
    .description-bloc {
      position: relative;
      font-weight: 600;
      padding: 4px 10px;
    }
    .server-error {
      margin-top: 20px;
      color: #ff6961;
    }
  }

  &.regie {
    width: 800px;
    max-width: 100%;
    min-width: 400px;

    .new-project-modal-wrapper {
      overflow-y: auto;
    }
  }
}
</style>
