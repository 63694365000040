var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-project-modal"},[_c('div',{staticClass:"new-project-content",class:{ regie: _vm.isFromRegie }},[(_vm.isFromRegie)?_c('div',{staticClass:"new-project-modal-wrapper"},[(_vm.getProjectsCreateError)?_c('div',{staticClass:"server-error"},[(_vm.getProjectsCreateError)?_c('div',{staticClass:"error"},[(Array.isArray(_vm.getProjectsCreateError))?_c('ul',_vm._l((_vm.getProjectsCreateError),function(e,index){return _c('li',{key:'get-projects-create-error-' + index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.getProjectsCreateError))])]):_vm._e()]):_vm._e()]):_c('div',{staticClass:"new-project-modal-wrapper"},[_c('div',{staticClass:"title-modal border-bottom-solid"},[_c('span',{staticClass:"font-sz-14 bold-700 font-text color-black"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Ajouter un nouveau projet' : 'Add a new project')+" ")]),_c('div',[_c('v-btn',{attrs:{"icon":"","fab":"","small":""},on:{"click":_vm.handleClose}},[_c('v-icon',[_vm._v("mdi-close ")])],1)],1)]),_c('div',{staticClass:"form-new-project"},[_c('v-form',{ref:"addNewProjet"},[_c('div',{staticClass:"description-bloc mb-2"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Type de projet' : 'Project type')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('v-select',{staticClass:"msg-error",attrs:{"clearable":true,"items":_vm.visibilityProjectsTypes,"required":"","loading":_vm.getProjectsTypesLoading,"validate-on-blur":"","rules":[
                v => !!v || _vm.$t('msgObligMsg', { msg: _vm.$t('typeProjet') })
              ],"item-text":"name","item-value":"id","dense":"","outlined":"","color":"#5C2DD3","item-color":"#5C2DD3","label":"Type","persistent-placeholder":true,"placeholder":_vm.$t('selectionner'),"no-data-text":_vm.$t('noDataOptionMsg', { option: _vm.$t('typeProjet') }),"menu-props":{
                bottom: true,
                offsetY: true
              }},on:{"change":function($event){return _vm.changePipelineSelonType(_vm.projectToAdd.type)}},model:{value:(_vm.projectToAdd.type),callback:function ($$v) {_vm.$set(_vm.projectToAdd, "type", $$v)},expression:"projectToAdd.type"}})],1),_c('div',{staticClass:"description-bloc"},[_c('v-autocomplete',{attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('assigned_to'),"item-color":"#5C2DD3","items":_vm.allUsers,"persistent-placeholder":true,"label":_vm.$t('assigned_to'),"item-text":"full_name","dense":"","item-value":"id","outlined":"","menu-props":{
                bottom: true,
                offsetY: true
              },"required":"","validate-on-blur":"","rules":[v => !!v || _vm.$t('assigned_to') + ' ' + _vm.$t('msgOblg')]},model:{value:(_vm.projectToAdd.project_owner),callback:function ($$v) {_vm.$set(_vm.projectToAdd, "project_owner", $$v)},expression:"projectToAdd.project_owner"}})],1),_c('div',{staticClass:"description-bloc margin-div mb-2"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Contact du projet' : 'Project contact')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('v-radio-group',{staticClass:"radio-dynamique",model:{value:(_vm.contactState),callback:function ($$v) {_vm.contactState=$$v},expression:"contactState"}},[_c('v-radio',{staticClass:"label-radio",attrs:{"label":_vm.$i18n.locale === 'fr'
                    ? 'Contact existant'
                    : 'Existing contact',"value":"old","color":"#5C2DD3"}}),_c('v-radio',{staticClass:"label-radio",attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Nouveau contact' : 'New contact',"value":"new","color":"#5C2DD3"}})],1)],1),_c('v-divider',{staticClass:"mb-2"}),(_vm.contactState == 'new')?_c('div',[_c('div',{staticClass:"description-bloc"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Détails du nouveau contact' : 'New contact details')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"msg-error",attrs:{"type":"text","label":_vm.$t('name'),"persistent-placeholder":true,"outlined":"","required":"","validate-on-blur":"","rules":[v => !!v || _vm.$t('name') + ' ' + _vm.$t('msgOblg')],"dense":"","color":"#5C2DD3"},model:{value:(_vm.contactToAdd.last_name),callback:function ($$v) {_vm.$set(_vm.contactToAdd, "last_name", $$v)},expression:"contactToAdd.last_name"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"msg-error",attrs:{"type":"text","label":_vm.$t('first_name'),"persistent-placeholder":true,"outlined":"","required":"","validate-on-blur":"","rules":[
                      v => !!v || _vm.$t('msgObligMsg', { msg: _vm.$t('first_name') })
                    ],"dense":"","color":"#5C2DD3"},model:{value:(_vm.contactToAdd.first_name),callback:function ($$v) {_vm.$set(_vm.contactToAdd, "first_name", $$v)},expression:"contactToAdd.first_name"}})],1)],1)],1)]):_c('div',[_c('div',{staticClass:"description-bloc"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Sélectionnez le contact existant' : 'Select existing contact')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('v-autocomplete',{staticClass:"msg-error",attrs:{"clearable":true,"items":_vm.getContactsList,"loading":_vm.getContactsProcessing,"required":"","validate-on-blur":"","rules":[
                  v =>
                    !!v ||
                    _vm.$t('msgObligMsg', {
                      msg:
                        _vm.$i18n.locale === 'fr'
                          ? 'Contact existant'
                          : 'Existing contact'
                    })
                ],"return-object":"","item-text":"full_name","item-value":"id","dense":"","outlined":"","color":"#5C2DD3","item-color":"#5C2DD3","label":"Contact","persistent-placeholder":true,"placeholder":_vm.$t('selectionner'),"no-data-text":_vm.$t('noDataOptionMsg', { option: 'contact' }),"menu-props":{
                  bottom: true,
                  offsetY: true
                }},on:{"update:search-input":_vm.asyncSearchContact},model:{value:(_vm.selectedContact),callback:function ($$v) {_vm.selectedContact=$$v},expression:"selectedContact"}})],1)])],1),_c('div',{staticClass:"actions-msg-btn-modal"},[(_vm.getProjectsCreateError)?_c('div',{staticClass:"error-msg"},[(Array.isArray(_vm.getProjectsCreateError))?_c('ul',_vm._l((_vm.getProjectsCreateError),function(e,index){return _c('li',{key:'get-projects-create-error-' + index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.getProjectsCreateError)+" ")])]):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-msg"},[(Array.isArray(_vm.error))?_c('ul',_vm._l((_vm.error),function(e,index){return _c('li',{key:'get-projects-create-error-' + index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.error)+" ")])]):_vm._e()])],1),_c('div',{staticClass:"action-new-projet"},[_c('v-btn',{attrs:{"color":"#5C2DD3","dark":"","loading":_vm.loading},on:{"click":_vm.addProject}},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Ajouter le projet' : 'Add the project')),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-chevron-right")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"text":"","variant":"outline-secondary"},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('btnCancel'))+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }