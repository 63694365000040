<template>
  <div class="overlay-modal" ref="modal">
    <portal to="new-project-modal">
      <ModalRightDynamic @closeModal="handleClose('openModal')">
        <template #title>
          <div
            class="font-sz-16 font-text bold-700 text-capitalize"
            v-if="!data"
          >
            {{ typeCrud }} {{ labelModal }}
          </div>
          <div
            class="font-sz-16 font-text bold-700 text-capitalize text-overflow-setting"
            v-else
          >
            {{ labelModal }} : {{ titleModalUpdate }}
          </div>
        </template>
        <template #content-form>
          <v-form ref="openModal" v-if="formDefault">
            <!-- TYPE RESOURCE -->
            <v-autocomplete
              class="mt-3"
              :clearable="true"
              color="#5C2DD3"
              @input="changeFieldsTypeResource"
              :loading="getTypeResourceLoading"
              :disabled="data ? true : false"
              :placeholder="
                $t('searchMsg', {
                  msg:
                    $i18n.locale === 'fr' ? 'type ressources' : 'resource type'
                })
              "
              item-color="#5C2DD3"
              v-model="formDefault.type"
              :items="getTypeResource"
              :persistent-placeholder="true"
              :label="
                $i18n.locale === 'fr' ? 'Type ressources' : 'Resource type'
              "
              item-text="designation"
              dense
              return-object
              item-value="id"
              :no-data-text="
                $t('noDataOptionMsg', {
                  option:
                    $i18n.locale === 'fr' ? 'type ressources' : 'resource type'
                })
              "
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
              validate-on-blur
              :rules="[
                v =>
                  !!v ||
                  ($i18n.locale === 'fr'
                    ? 'type ressources'
                    : 'resource type') +
                    ' ' +
                    $t('msgOblg')
              ]"
            >
            </v-autocomplete>
            <!-- CHAMP DYNAMIQUE  -->
            <div
              v-if="
                formDefault.type &&
                  formDefault.type.fields &&
                  formDefault.type.fields.length
              "
            >
              <v-row
                v-for="field in formDefault.type.fields"
                :key="'field-add-' + field.id + `${formDefault.type.id}`"
              >
                <v-col>
                  <div class="value">
                    <EditableCustomFieldForm
                      :readonly="false"
                      :field="field"
                      :value="field.value"
                      :typeRessource="formDefault.type.fields"
                      @updateFunction="updateFunction"
                      :fieldKeyEdit="false"
                      :required="field.required"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- IMPORTE IMAGE / FILE -->
            <div class="container" @drop="handleFileInputAdd">
              <div class="file-wrapper">
                <input
                  type="file"
                  name="file-input"
                  id="uploadInput"
                  multiple
                  @change="handleFileInputAdd"
                />
                <div
                  class="upload_label"
                  :key="`container_${tableKey}`"
                  :class="{
                    top:
                      formDefault &&
                      formDefault.files &&
                      formDefault.files.length >= 3
                  }"
                >
                  <font-awesome-icon
                    :icon="['far', 'file-arrow-up']"
                    class="icon-upload"
                  />
                  <p class="color-black mb-2">
                    <span class="drag-title"
                      ><u>
                        {{
                          $i18n.locale === 'fr'
                            ? 'Click pour uploader'
                            : 'Click to upload'
                        }}</u
                      ></span
                    >
                    {{
                      $i18n.locale === 'fr'
                        ? 'Ou glissez et déposer'
                        : 'or drag and drop'
                    }}
                  </p>
                  <p class=" mt-0 mb-0">SVG, PNG, JPG or GIF (max. 3MB)</p>
                </div>
                <div
                  class="upload_label"
                  :class="{
                    top:
                      formDefault &&
                      formDefault.files &&
                      formDefault.files.length >= 3
                  }"
                >
                  <div
                    class="drag-title"
                    v-if="
                      formDefault &&
                        formDefault.files &&
                        formDefault.files.length
                    "
                  >
                    <div
                      class="drag-title-file"
                      v-for="(item, index) in Array.from(
                        formDefault.files
                      ).slice(0, 2)"
                      :key="index"
                    >
                      <div class="mt-1">{{ item.name }}</div>
                    </div>
                    <div v-if="formDefault.files.length > 2" class="mt-2">
                      ({{ formDefault.files.length - 2 }}
                      {{ $i18n.locale === 'fr' ? 'autres' : 'others' }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- SHOW FILE UPLOAD -->
            <div v-if="files && files.length" class="block-file-upload">
              <Cover
                :key="keyCover"
                :files="files"
                @coverFile="coverFile"
                @removeFile="removeFile"
                @removeFileNotSave="removeFileNotSave"
              ></Cover>
            </div>
            <!-- ACTIVE / DESACTIVE RESOURCE -->
            <v-switch
              :label="!formDefault.active ? $t('diabled') : $t('active')"
              color="rgb(69, 20, 143)"
              v-model="formDefault.active"
              :class="{ 'color-bold': formDefault.active }"
              class="bold-700 ml-2 margin-switch-input width-switch-proposals mt-2"
              :true-value="1"
              :false-value="0"
            >
            </v-switch>
          </v-form>
        </template>
        <template #content-message>
          <div class="message">
            <div v-if="getResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>
        </template>
        <template #content-actions>
          <v-btn
            color="#45148F"
            dark
            @click.prevent.stop="handlValidation"
            :loading="
              getResourceLoading && (!errorMessages || !errorMessages.length)
            "
          >
            <span class="title-btn-edit-info text-capitalize">
              {{ typeCrud }}
              {{ labelModal }}</span
            >
          </v-btn>
          <v-btn
            text
            class="ml-3"
            @click.prevent.stop="handleClose('openModal')"
            variant="outline-secondary"
          >
            <span
              class="font-text bold-600 font-sz-12 color-black text-capitalize"
            >
              {{ $t('btnCancel') }}</span
            >
          </v-btn></template
        >
      </ModalRightDynamic>
    </portal>
  </div>
</template>
<script>
import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import Cover from '@/views/CRMILA26/Projects/immobilier/pipline/Cover/Cover.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'modal-resource-immo',
  props: {
    data: { required: true },
    labelModal: { required: true },
    typeCrud: { required: true }
  },
  components: {
    ModalRightDynamic,
    Cover,
    EditableCustomFieldForm: () =>
      import('@/components/CrmIla26Component/EditableCustomFieldForm')
  },
  data() {
    return {
      errorMessages: null,
      objetDefault: {
        // designation: null,
        // detail: null,
        type: null,
        fields: [],
        type_projet: null,
        active: 1,
        filesNotCoverFile: [],
        cover: null,
        cover_id: null
      },
      cover: null,
      files: [],
      titleModalUpdate: null,
      tableKey: 0,
      newUploadFile: false,
      keyCover: 0,
      fileBinary: null
    }
  },
  methods: {
    ...mapActions(['fetchAllTypeResource', 'fetchOneTypeResource']),
    coverFile(cover) {
      const fileListArray = this.fileBinary
        ? Array.from(this.fileBinary)
        : this.fileBinary
      if (fileListArray && fileListArray.length) {
        this.formDefault.filesNotCoverFile = fileListArray.filter(item => {
          return item.size !== cover.size && item.name !== cover.title
        })
        this.formDefault.cover = fileListArray.find(item => {
          return item.size == cover.size && item.name == cover.title
        })
      }
      this.cover = cover
    },
    refreshComponentsFiles() {
      this.tableKey++
    },
    refreshCoverFiles() {
      this.keyCover++
    },
    handleFileInputAdd(e) {
      this.fileBinary = e && e.target && e.target.files ? e.target.files : []
      this.newUploadFile = true
      this.files =
        this.data && this.data.files && this.data.files.length
          ? this.data.files.filter(item => item.entity_id)
          : []
      if (!this.data) {
        this.uploadFile(e)
      } else {
        this.uploadFile(e)
      }
      this.refreshComponentsFiles()
      this.refreshCoverFiles()
    },
    uploadFile(e) {
      const files = e && e.target && e.target.files ? e.target.files : []
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const fileUrl = URL.createObjectURL(file)
          // Trouver l'index de la dernière occurrence de "." dans le nom du fichier
          const lastDotIndex = file.name.lastIndexOf('.')
          // Vérifier si "." est trouvé dans le nom du fichier
          if (lastDotIndex !== -1) {
            // Extraire l'extension du fichier à partir de l'index trouvé
            const fileExtension = file.name
              .substring(lastDotIndex + 1)
              .toLowerCase()
            // Créer un nouvel objet file
            const newFile = {
              created_at: new Date().toISOString(),
              extension: fileExtension,
              id: i + 1,
              path: `uploads/${file.name}`,
              ressource_id: null,
              size: file.size,
              title: file.name,
              updated_at: new Date().toISOString(),
              url: fileUrl,
              check: false
            }
            // Ajouter le nouvel objet file au tableau de fichiers
            this.files.push(newFile)
          }
        }
      }
    },
    updateFunction(field) {
      this.formDefault.fields = field
    },
    async changeFieldsTypeResource(field) {
      if (field) {
        await this.fetchOneTypeResource(field.id)
      }
    },
    handleClose() {
      this.$emit('close')
      this.files = []
      this.newUploadFile = false
    },
    handlValidation() {
      if (this.$refs.openModal.validate()) {
        const formData = new FormData()
        // formData.append(
        //   'designation',
        //   this.formDefault.designation ? this.formDefault.designation : ''
        // )
        // formData.append(
        //   'detail',
        //   this.formDefault.detail ? this.formDefault.detail : ''
        // )
        formData.append('active', this.formDefault.active)
        if (this.formDefault.type) {
          formData.append(
            'type_resource_id',
            this.formDefault.type && this.formDefault.type.id
              ? this.formDefault.type.id
              : this.formDefault.type
          )
        }
        if (this.formDefault.fields && this.formDefault.fields.length) {
          for (let index = 0; index < this.formDefault.fields.length; index++) {
            formData.append(
              'fields[' + index + '][value]',
              this.formDefault.fields[index].value
                ? this.formDefault.fields[index].value
                : ''
            )
            formData.append(
              'fields[' + index + '][id]',
              this.formDefault.fields[index].id
            )
          }
        }
        // ----------------------------------------- IMPORT FILES -------------------------------------
        if (
          this.formDefault.filesNotCoverFile &&
          this.formDefault.filesNotCoverFile.length
        ) {
          this.formDefault.filesNotCoverFile.forEach(file => {
            formData.append('files[]', file)
          })
        } else {
          if (!this.cover && this.fileBinary && this.fileBinary.length) {
            const fileListArray = Array.from(this.fileBinary)
            fileListArray.forEach(file => {
              formData.append('files[]', file)
            })
          }
        }
        // COVER FILE
        if (this.formDefault.cover) {
          formData.append('cover', this.formDefault.cover)
        }
        // CAS UPDATE AND NOT UPLODE OTHERS FILE
        if (
          this.data &&
          this.cover &&
          this.files &&
          this.files.length &&
          this.cover.entity_id
        ) {
          formData.append('cover_id', this.cover.id)
        }
        // Validation
        this.$emit('valider', formData, this.formDefault)
      }
    },
    removeFile(file) {
      const formData = new FormData()
      if (file) {
        formData.append('file_ids[]', file.id)
      }
      this.$emit('removeFile', formData, this.formDefault)
      this.refreshComponentsFiles()
      this.refreshCoverFiles()
    },
    removeFileNotSave(fileToRemove) {
      const index = this.files.findIndex(file => file === fileToRemove)
      if (index !== -1) {
        this.files.splice(index, 1)
      }
      let fileListArray = this.fileBinary
        ? Array.from(this.fileBinary)
        : this.fileBinary
      if (fileListArray && fileListArray.length) {
        this.fileBinary = fileListArray.filter(item => {
          return (
            item.size !== fileToRemove.size && item.name !== fileToRemove.title
          )
        })
      }

      // Tester si la couverture est correcte
      if (this.fileBinary && this.fileBinary.length) {
        if (this.formDefault.cover) {
          let cover =
            this.fileBinary.find(
              item =>
                item.size === this.formDefault.cover.size &&
                item.name === this.formDefault.cover.name
            ) || null
          if (cover) {
            this.formDefault.filesNotCoverFile = this.fileBinary.filter(
              item => {
                return item.size !== cover.size && item.name !== cover.name
              }
            )
          }
          this.formDefault.cover = cover
        } else {
          this.formDefault.filesNotCoverFile = this.fileBinary
          this.formDefault.cover = null
        }
      } else {
        this.formDefault.filesNotCoverFile = []
        this.formDefault.cover = null
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTypeResourceLoading',
      'getTypeResource',
      'getResourceLoading',
      'getResourceError',
      'getTypeResourceError'
    ]),
    formDefault: function() {
      let objet = this.data ? this.data : this.objetDefault
      return objet
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getResourceError(newValue) {
      this.errorMessages = newValue
    },
    getTypeResourceError(newValue) {
      this.errorMessages = newValue
    }
  },
  mounted() {
    // EN CAS EDIT AFFECTER OBJET FILES A TABLE FILES
    if (this.data) {
      this.files = this.data.files
      this.formDefault.type.fields = this.formDefault.fields
      this.titleModalUpdate = this.data.designation
    }
    // liste resource filtre par type de projet
    if (localStorage.getItem('typeProject')) {
      this.fetchAllTypeResource({
        type_projet_id: localStorage.getItem('typeProject')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// ----------------------------------- style image drag and drop -----------------------------------
.container {
  height: 230px;
  width: 100%;
  .file-wrapper {
    text-align: center;
    width: 105%;
    height: 100%;
    margin: 0px 0px 0px -12px;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    border: 1px dashed rgba(92, 45, 211, 1);
    background-color: transparent;
    border-radius: 5px;
    box-shadow: 1px 2px 5px 5px #f5f5f5;
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 5;
    }
    .display {
      display: none;
    }
    .upload_label {
      font-size: 40px;
      position: relative;
      top: 32px;
      &.top {
        top: 12px;
      }
      .drag-title {
        text-decoration: underline;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #704ad1;
      }
      .drag-title-file {
        text-decoration: underline;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #704ad1;
      }
      .color-black {
        color: #000;
      }
      p {
        font-size: 16px;
      }
      .icon-upload {
        width: 33px;
        height: 33px;
        margin-bottom: 15px;
        color: #704ad1;
      }
      .btn-upload-file {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        width: 60%;
        border-radius: 29px;
        background-color: #704ad1;
        padding: 11px 20px;
        border: 0;
        color: #fff;
        margin: auto;
      }
    }
  }
}
.drag-title-file {
  text-decoration: underline;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #704ad1;
}
.overlay-modal {
  position: fixed;
  height: calc(100% - 0px);
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;
}
</style>
<style lang="scss">
.header-block {
  .block-action-add-propoasls {
    .v-btn:not(.v-btn--round).v-size--default {
      height: 33px !important;
    }
  }
}
.delete-projet-proposals {
  color: #45148f;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  &:hover {
    text-decoration: underline;
  }
}
.list-class-proposals {
  display: flex;
  .item-list {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    line-height: 24px;
    gap: 5px;
    letter-spacing: 0px;
    color: #2f2f2f;
    padding: 0px 9px 0px 6px;
    border-right: 1px solid #c9c8d3;
    align-items: center;
    justify-content: flex-start;
    &:hover {
      background: #f1f5f9;
    }
  }
}
.list-class-proposals-modal {
  display: flex;
  .item-list,
  .item-list-hover {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    line-height: 24px;
    gap: 5px;
    letter-spacing: 0px;
    color: #2f2f2f;
    padding: 0px 9px 0px 6px;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    &.border-right {
      border-right: 1px solid #c9c8d3;
    }
  }
  .item-list-hover {
    &:hover {
      background: #f1f5f9;
    }
  }
}
.label-radio-propoasls {
  .v-label {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    color: #212121 !important;
  }
}
.label-global {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 17.07px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.label-btn-form-immo {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: 'Montserrat', sans-serif !important;
  text-align: center !important;
  text-transform: capitalize !important;
}
.label-btn-edit-view {
  font-size: 13px !important;
  position: relative;
  top: 2px;
  font-family: 'Montserrat', sans-serif !important;
  text-transform: capitalize !important;
}
.icon-edit-view {
  top: 5px;
  position: relative;
}
.line-hr-modal-proposals {
  margin-top: -10px;
}
.width-switch-proposals {
  width: 30%;
}
.btn-edit-view {
  .v-btn:not(.v-btn--round).v-size--small {
    height: 0px !important;

    &:hover {
      background-color: transparent !important;
    }
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
  }
}
.label-header-card-immo {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0px;
  color: #45148f !important;
}
.block-file-upload {
  border: 1px solid #ece8f4;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 190px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #45148f;
  }
}
</style>
