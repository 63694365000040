<template>
  <div class="type-file-upload" v-if="filesCover">
    <v-row>
      <v-col
        v-for="(file, index) in filesCover"
        :key="'file-' + file.id + index"
        cols="3"
        class="text-center"
        @click="changeFile(file)"
      >
        <!-- :class="{ 'radio-none': !isImage(file) && !isPdf(file) }" -->
        <v-radio-group
          class="cover-image"
          ref="radioFile"
          v-model="selectedFile"
          @change="changeFile(file)"
          hide-details=""
        >
          <v-radio class="input-radio-file" :value="file" color="#2f2f2f">
            <template v-slot:label>
              <div class="image-container">
                <template v-if="isImage(file)">
                  <img :src="file.url" class="size-image" :title="file.title" />
                </template>
                <template v-else-if="isPdf(file)">
                  <iframe width="500" height="500" :src="file.url"></iframe>
                </template>
                <template v-else>
                  <v-icon
                    class="icon-extension-file"
                    :color="getFileColor(file)"
                    dark
                    large
                    >{{ getFileIcon(file) }}</v-icon
                  >
                </template>
                <v-icon
                  :style="computedStyle(file)"
                  class="font-sz-18"
                  @click.prevent.stop="removeFile(file)"
                  >mdi-close</v-icon
                >
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-card-title class="block-flex-align-space-between color-card-title">
          <div class="label">
            <span class="font-sz-11 font-text color-black label-grid ">
              {{ formatTitleFile(file) ? formatTitleFile(file) : '' }}
            </span>
          </div>
        </v-card-title>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    files: { required: false }
  },
  data() {
    return {
      selectedFile: null,
      filesCover: this.files
    }
  },
  methods: {
    ...mapActions([]),
    changeFile(file) {
      // Désactiver le fichier radio si ce n'est pas une image ou pdf
      // if (this.isImage(file) || this.isPdf(file)) {
      this.selectedFile = file
      // }
      // else {
      //   this.affecterCover()
      // }
      this.$emit('coverFile', this.selectedFile)
    },
    affecterCover() {
      if (this.filesCover && this.filesCover.length) {
        let cover = this.filesCover.find(item => item.cover == 1)
        if (cover) {
          this.selectedFile = cover
        }
      }
    },
    removeFile(fileToRemove) {
      const index = this.filesCover.findIndex(file => file === fileToRemove)
      if (index !== -1) {
        this.filesCover.splice(index, 1)
      }
      if (fileToRemove.entity_id) {
        this.$emit('removeFile', fileToRemove)
      } else {
        this.$emit('removeFileNotSave', fileToRemove)
      }
      // // Désactiver le fichier radio si ce n'est pas une image
      // this.selectedFile =
      //   this.isImage(fileToRemove) || this.isPdf(fileToRemove)
      //     ? fileToRemove
      //     : null
    },

    isImage(file) {
      const extensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif']
      return extensions.includes(file.extension.toLowerCase())
    },
    isPdf(file) {
      return file.extension.toLowerCase() === 'pdf'
    },
    getFileColor(file) {
      const colors = {
        doc: '#1a73e8',
        docx: '#1a73e8',
        ppt: '#cb4424',
        pptx: '#cb4424',
        xls: '#1d6b40',
        xlsx: '#1d6b40',
        txt: '#44abca',
        odt: '#44abca',
        ods: '#44abca',
        lnk: '#0000008a'
      }
      return colors[file.extension.toLowerCase()] || ''
    },
    getFileIcon(file) {
      const icons = {
        doc: 'mdi-file-word',
        docx: 'mdi-file-word',
        ppt: 'mdi-file-powerpoint',
        pptx: 'mdi-file-powerpoint',
        xls: 'mdi-file-excel',
        xlsx: 'mdi-file-excel',
        txt: 'mdi-file',
        odt: 'mdi-file',
        ods: 'mdi-file',
        lnk: 'mdi-link'
      }
      return icons[file.extension.toLowerCase()] || 'mdi-file-outline'
    }
  },
  computed: {
    computedStyle() {
      return function(file) {
        if (this.isImage(file)) {
          return {
            cursor: 'pointer',
            color: 'rgb(255, 255, 255)',
            position: 'absolute',
            right: '-4px',
            zIndex: '6',
            backgroundColor: 'rgb(232, 19, 19)',
            top: '-4px',
            borderRadius: '20px'
          }
        } else if (this.isPdf(file)) {
          return {
            cursor: 'pointer',
            color: '#fff',
            position: 'absolute',
            right: '-3px',
            backgroundColor: '#e81313',
            top: '-5px',
            zIndex: '6',
            borderRadius: '20px'
          }
        } else {
          return {
            cursor: 'pointer',
            color: '#fff',
            position: 'absolute',
            right: '-3px',
            backgroundColor: '#e81313',
            top: '-5px',
            zIndex: '6',
            borderRadius: '20px'
          }
        }
      }
    },
    formatTitleFile() {
      return function(file) {
        // Supprimer la partie initiale du titre du fichier
        return file.entity_id ? file.title.replace(/^[^/.]+\./, '') : file.title
      }
    }
  },
  mounted() {
    this.affecterCover()
  }
}
</script>

<style lang="scss" scoped>
.type-file-upload {
  .icon-extension-file {
    font-size: 25px !important;
    text-align: center !important;
    border: 1px solid;
    padding: 16px;
    margin: 22px 26px;
    width: 25px;
    border-radius: 5px;
    height: 25px !important;
    caret-color: transparent !important;
    &:hover,
    &:focus,
    &:active {
      caret-color: transparent !important;
    }
  }
  .label {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100% !important;
    white-space: nowrap !important;
  }
  .size-image {
    // width: 85%;
    // height: 76px;
    width: 100%;
    height: inherit;
  }
  .size-file {
    width: 100%;
    height: inherit;
  }
}
.image-container {
  width: 88px;
  height: 76px;
  border: 1px solid #ece8f4;
  overflow: hidden;
  border-radius: 5px;
}
.color-card-title {
  padding: 0px !important;
  font-size: 11px !important;
}
</style>
<style lang="scss">
.cover-image {
  .v-input--selection-controls__ripple {
    height: 0px !important;
    width: 0px !important;
    left: 0px !important;
    top: 0px !important;
    margin: 0px !important;
  }
  .input-radio-file {
    .v-icon {
      font-size: 18px !important;
    }
    .image-container {
      .icon-extension-file {
        font-size: 25px !important;
      }
    }
  }
  .v-input--selection-controls__input {
    margin-right: 8px !important;
    position: absolute !important;
    top: 3px !important;
    z-index: 1 !important;
    left: 3px !important;
    background-color: rgb(255 255 255 / 81%) !important;
  }
  .v-input--selection-controls__input {
    border-radius: 50% !important;
    // border-radius: 68% !important;
    height: 15px;
    width: 14px;
  }
  &.radio-none {
    .v-input--selection-controls__input {
      display: none;
      pointer-events: none;
    }
  }
}
</style>
