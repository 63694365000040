<!--**************** NB : il existe des conditions d'affihage pour le type Immo et pour le type par defaut(ancien)  ********************-->
<template>
  <div class="add-project-component">
    <custom-drop-down-menu class="drop-down-add-project" :nudgeWidth="250">
      <template #header>
        <v-btn class="btn-add-projet mr-3" color="#45148F" dark small>
          <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
          {{ $t('btnAdd') }}
        </v-btn>
      </template>
      <!-- Liste de choix -->
      <template #body>
        <div class="add-project-body-component">
          <div
            class="item-add-component"
            @click="createNewContact"
            v-if="getCurentProjectTypeImmo"
          >
            {{ $t('contact_id') }}
          </div>
          <div class="item-add-component" @click="createNewProject">
            {{ $t('opportunity') }}
          </div>
          <div
            class="item-add-component"
            @click="createNewResource"
            v-if="getCurentProjectTypeImmo"
          >
            {{ $t('project_id') }}
          </div>
        </div>
      </template></custom-drop-down-menu
    >

    <!-- --------------------------------------- NEW CONTACT Pour Type IMMO ----------------------------------- -->
    <ModalContact
      v-if="newContact && getCurentProjectTypeImmo"
      :data="null"
      labelModal="contact"
      :typeCrud="$t('btnAdd')"
      @close="closeModal('newContact')"
      @valider="handleNewCreateContact"
    ></ModalContact>

    <!-- --------------------------------------- NEW OPPORTUNITY (PROJET) Pour Type IMMO ----------------------------------- -->
    <NewOpportunityModal
      v-if="newProjectState && getCurentProjectTypeImmo"
      @close="closeModal('newProjectState')"
      @created="handleNewCreateProject"
      :currentType="getCurentProjectType"
    ></NewOpportunityModal>
    <!-- --------------------------------------- NEW RESOURCE Pour Type IMMO----------------------------------- -->
    <NewResourceModal
      v-if="newResource && getCurentProjectTypeImmo"
      :data="null"
      :labelModal="$t('project_id')"
      :typeCrud="$t('btnAdd')"
      @close="closeModal('newResource')"
      @valider="handleNewCreateResource"
    ></NewResourceModal>

    <!-- --------------------------------------- NEW OPPORTUNITY (PROJET) Pour Type Par defaut (Ancien) ----------------------------------- -->

    <AddProject
      v-if="newProjectState"
      @close="closeModal('newProjectState')"
      @created="handleNewCreateProject"
      :currentType="getCurentProjectType"
    ></AddProject>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomDropDownMenu from '../immobilier/pipline/immoTable/component/customDropDownMenu.vue'
import ModalContact from '@/views/CRMILA26/Projects/immobilier/pipline/Contact/ModalContact.vue'
import NewResourceModal from '@/views/CRMILA26/Projects/immobilier/pipline/Catalog/NewResourceModal.vue'
import AddProject from './NewProjectModal.vue'
export default {
  watch: {},
  data() {
    return {
      newProjectState: false,
      newContact: false,
      newResource: false
    }
  },
  computed: {
    ...mapGetters(['getCurentProjectType', 'getCurentProjectTypeImmo'])
  },
  methods: {
    ...mapActions([
      'refreshProjectExtra',
      'addCustomContact',
      'addResource',
      'resetErrorCustomContacts',
      'resetErrorResource'
    ]),
    // Modal New Project
    closeModal(ref) {
      this[ref] = false
      if (ref == 'newContact') {
        this.resetErrorCustomContacts()
      } else if (ref == 'newResource') {
        this.resetErrorResource()
      }
    },
    // Partie New projet
    async handleNewCreateProject(project) {
      await this.refreshProjectExtra()
      if (project && project.id) {
        this.$router.push('/projects/' + project.id)
      }
      this.newProjectState = false
    },
    createNewProject() {
      this.newProjectState = true
    },
    // Partie New contact

    createNewContact() {
      this.newContact = true
    },
    async handleNewCreateContact(payload) {
      const reponse = await this.addCustomContact(payload)
      if (reponse && reponse.contact) {
        this.closeModal('newContact')
      }
    },

    // Partie Resource
    createNewResource() {
      this.newResource = true
    },
    async handleNewCreateResource(formData) {
      const response = await this.addResource(formData)
      if (response && response.success) {
        this.closeModal('newResource')
      }
    }
  },

  components: {
    CustomDropDownMenu,
    ModalContact,
    NewResourceModal,
    AddProject,
    NewOpportunityModal: () =>
      import('@/views/CRMILA26/Projects/Dialogs/NewOpportunityModal.vue')
  }
}
</script>
<style scoped lang="scss">
.add-project-component {
  .btn-add-projet {
    width: 100%;

    border-radius: 4px;
    padding: 8px 10px 8px 10px;
    font-size: 11px;
    height: 32px !important;

    .sz-icon-plus {
      font-size: 17px !important;
    }
    text-transform: capitalize;
  }
}
.drop-down-add-project {
  margin-right: 50px;
}
.add-project-body-component {
  .item-add-component {
    padding: 5px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    &:hover {
      background: #ece8f4;
    }
  }
}
</style>
