<template>
  <!----------------------------------------------Affichage pour projet IMMO --------------------------------------------- -->

  <v-app class="nav-header" v-if="getCurentProjectTypeImmo">
    <v-app-bar app class="nav-header-bar-Immo">
      <div class="menu-corner-Immo">
        <div class="" :class="{ 'logo-arrow-left': !hiddenMenuCrm }">
          <router-link :to="'/'">
            <font-awesome-icon
              :icon="['far', 'building']"
              style="
                color: #ffffff;
                width: 20px;
                height: 20px;
                margin-right: 10px;
                margin-left: -5px;
              "
            />
            <span class="Title-General-Energie">GENERALE FRANCE ENERGIE</span>
          </router-link>
          <!-- <router-link :to="'/'" class="router-link">
            <div class="logo-crm mb-2">CRM</div>
          </router-link> -->
        </div>
        <div class="flex top-margin align-center">
          <!-- <div size="sm" class="btn-sidebar mt-1" @click="flechBackTopNavBar">
            <font-awesome-icon
              :icon="['fal', 'arrow-left-to-line']"
              class="color-icon-mdi cursor-pointer img-h-22"
              v-if="!hiddenMenuCrm"
            />
            <font-awesome-icon
              v-else
              class="cursor-pointer color-icon-mdi img-h-22"
              :icon="['fal', 'arrow-right-to-line']"
            />
          </div> -->
          <!-- <div size="sm" class="btn-sidebar mt-1" @click="openDashboard">
            <font-awesome-icon
              :icon="['fas', 'grid-2']"
              class="img-h-22 color-icon-mdi cursor-pointer"
            />
          </div> -->
          <!-- <div size="sm" class="ml-3">
            <v-icon
              class="color-icon-mdi pr-3 border-right-solid cursor-pointer "
              font="mdi"
              >mdi-lock-outline</v-icon
            >
          </div> -->

          <font-awesome-icon
            :icon="['fal', 'pipe']"
            class="line-vertical"
            style="margin-left: 15px; height: 30px; margin-top: 4px;"
          />
          <!-- START  -->
          <div class="menu-crm-web-project-Immo mr-15 ml-2">
            <div
              v-for="item in menuCrmWebTypeProjet"
              :key="item.value"
              class="menu-item block-flex-align-space-between"
              @click="handleProjet"
            >
              <div class="count mr-2">
                {{
                  getCurentProjectType && getCurentProjectType.projet_count
                    ? getCurentProjectType.projet_count
                    : getProjectsTypes && getProjectsTypes.length
                    ? getProjectsTypes.length
                    : 0
                }}
              </div>
              <div
                class="mr-2 color-title"
                v-if="getCurentProjectType && getCurentProjectType.name"
              >
                {{ getCurentProjectType.name }}
              </div>
              <div class="mr-2 color-title" v-else>
                {{ $t(`${item.cleTraduction}`) }}
              </div>
              <!-- TYPES DES PROJETS -->
              <v-menu
                offset-y
                v-model="openTypeProjet"
                origin="center center"
                transition="scale-transition"
                :nudge-bottom="8"
                :nudge-left="200"
                :nudge-width="300"
              >
                <template v-slot:activator="{ attrs, on }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    :class="{
                      'icon-flech-menu': !getProjectsTypesLoading
                    }"
                    :disabled="getProjectsTypesLoading"
                  >
                    <v-progress-circular
                      v-if="getProjectsTypesLoading"
                      indeterminate
                      color="#5C2DD3"
                      class="sz-loader-icon"
                    ></v-progress-circular>
                    <v-icon class="icon-flech" v-else>mdi-chevron-down</v-icon>
                  </div>
                </template>
                <v-list class="list-type-projet-scroll">
                  <div v-if="getProjectsTypes && getProjectsTypes.length">
                    <v-list-item
                      v-for="itemS in getProjectsTypes"
                      :key="itemS.id"
                      class="list-type-projet-item"
                      @click.prevent.stop="switchType(itemS)"
                      :class="{
                        active:
                          getCurentProjectType &&
                          getCurentProjectType.id == itemS.id
                      }"
                      link
                    >
                      <v-list-item-title
                        class="list-type-onglet-projet-accueil block-flex-align-space-between"
                      >
                        <div
                          class="font-text font-sz-12 bold-700 title-type-popover"
                        >
                          {{ itemS.name }}
                        </div>
                        <div class="block-right">
                          <span class="count">
                            {{
                              itemS && itemS.projet_count
                                ? itemS.projet_count
                                : '0'
                            }}
                          </span>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                  <div v-else>
                    <v-list-item
                      class="text-center font-sz-14 font-text"
                      v-if="
                        (!getProjectsTypes || !getProjectsTypes.length) &&
                          !getProjectsTypesLoading
                      "
                    >
                      {{ $t('aucun') }} {{ $t('typeProjet') }}
                    </v-list-item>
                    <v-list-item
                      v-if="
                        (!getProjectsTypes || !getProjectsTypes.length) &&
                          getProjectsTypesLoading
                      "
                      class="color-crm font-text font-sz-12"
                    >
                      {{ $t('loading') }}
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </div>
          </div>
          <SearchImmo
            class="center-container"
            style="
              display: flex;
              left: 50%;
              transform: translateX(-50%);

              justify-content: center;
              align-items: center;
              margin-left: auto;
              margin-top: 5px;
              position: absolute;

            "
          />
          <!-- END  -->
        </div>
      </div>
      <!-- <div class="profile-corner">
        <DropDownChat />
      </div> -->
      <div class="profile-corner-Immo mt-0">
        <DropDownl18nImmo class="mr-3" @langage="langageTraduire" />
        <!-- <v-col
          ><font-awesome-icon :icon="['fal', 'pipe']" class="line-vertical"
        /></v-col> -->
        <!-- <DropDownNotification class="mr-3" /> -->
        <DropDownSettingImmo />
      </div>
    </v-app-bar>
  </v-app>

  <!----------------------------------------------Affichage pour tout les projects sauf IMMO --------------------------------------------- -->
  <v-app class="nav-header" v-else>
    <v-app-bar app class="nav-header-bar">
      <div class="menu-corner">
        <div class="logo" :class="{ 'logo-arrow-left': !hiddenMenuCrm }">
          <router-link :to="'/'">
            <img src="@/assets/img/ilan26.png" />
          </router-link>
          <!-- <router-link :to="'/'" class="router-link">
            <div class="logo-crm mb-2">CRM</div>
          </router-link> -->
        </div>
        <div class="flex top-margin align-center">
          <div size="sm" class="btn-sidebar mt-1" @click="flechBackTopNavBar">
            <font-awesome-icon
              :icon="['fal', 'arrow-left-to-line']"
              class="color-icon-mdi cursor-pointer img-h-22"
              v-if="!hiddenMenuCrm"
            />
            <font-awesome-icon
              v-else
              class="cursor-pointer color-icon-mdi img-h-22"
              :icon="['fal', 'arrow-right-to-line']"
            />
          </div>
          <div size="sm" class="btn-sidebar mt-1" @click="openDashboard">
            <font-awesome-icon
              :icon="['fas', 'grid-2']"
              class="img-h-22 color-icon-mdi cursor-pointer"
            />
          </div>
          <!-- <div size="sm" class="ml-3">
            <v-icon
              class="color-icon-mdi pr-3 border-right-solid cursor-pointer "
              font="mdi"
              >mdi-lock-outline</v-icon
            >
          </div> -->

          <!-- START  -->
          <div class="menu-crm-web-project mr-3 ml-2">
            <div
              v-for="item in menuCrmWebTypeProjet"
              :key="item.value"
              class="menu-item block-flex-align-space-between"
              @click="handleProjet"
            >
              <div class="count mr-2">
                {{
                  getCurentProjectType && getCurentProjectType.projet_count
                    ? getCurentProjectType.projet_count
                    : getProjectsTypes && getProjectsTypes.length
                    ? getProjectsTypes.length
                    : 0
                }}
              </div>
              <div
                class="mr-2 color-title"
                v-if="getCurentProjectType && getCurentProjectType.name"
              >
                {{ getCurentProjectType.name }}
              </div>
              <div class="mr-2 color-title" v-else>
                {{ $t(`${item.cleTraduction}`) }}
              </div>
              <!-- TYPES DES PROJETS -->
              <v-menu
                offset-y
                v-model="openTypeProjet"
                origin="center center"
                transition="scale-transition"
                :nudge-bottom="8"
                :nudge-left="200"
                :nudge-width="300"
              >
                <template v-slot:activator="{ attrs, on }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    :class="{
                      'icon-flech-menu': !getProjectsTypesLoading
                    }"
                    :disabled="getProjectsTypesLoading"
                  >
                    <v-progress-circular
                      v-if="getProjectsTypesLoading"
                      indeterminate
                      color="#5C2DD3"
                      class="sz-loader-icon"
                    ></v-progress-circular>
                    <v-icon class="icon-flech" v-else>mdi-chevron-down</v-icon>
                  </div>
                </template>
                <v-list class="list-type-projet-scroll">
                  <div v-if="getProjectsTypes && getProjectsTypes.length">
                    <v-list-item
                      v-for="itemS in getProjectsTypes"
                      :key="itemS.id"
                      class="list-type-projet-item"
                      @click.prevent.stop="switchType(itemS)"
                      :class="{
                        active:
                          getCurentProjectType &&
                          getCurentProjectType.id == itemS.id
                      }"
                      link
                    >
                      <v-list-item-title
                        class="list-type-onglet-projet-accueil block-flex-align-space-between"
                      >
                        <div
                          class="font-text font-sz-12 bold-700 title-type-popover"
                        >
                          {{ itemS.name }}
                        </div>
                        <div class="block-right">
                          <span class="count">
                            {{
                              itemS && itemS.projet_count
                                ? itemS.projet_count
                                : '0'
                            }}
                          </span>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                  <div v-else>
                    <v-list-item
                      class="text-center font-sz-14 font-text"
                      v-if="
                        (!getProjectsTypes || !getProjectsTypes.length) &&
                          !getProjectsTypesLoading
                      "
                    >
                      {{ $t('aucun') }} {{ $t('typeProjet') }}
                    </v-list-item>
                    <v-list-item
                      v-if="
                        (!getProjectsTypes || !getProjectsTypes.length) &&
                          getProjectsTypesLoading
                      "
                      class="color-crm font-text font-sz-12"
                    >
                      {{ $t('loading') }}
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </div>
          </div>
          <Search />
          <!-- END  -->
        </div>
      </div>
      <!-- <div class="profile-corner">
        <DropDownChat />
      </div> -->
      <div class="profile-corner mt-0">
        <DropDownI18n class="mr-3" @langage="langageTraduire" />
        <v-col
          ><font-awesome-icon :icon="['fal', 'pipe']" class="line-vertical"
        /></v-col>
        <DropDownNotification class="mr-3" />
        <DropDownSetting />
      </div>
    </v-app-bar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Topbar',

  data() {
    return {
      hiddenMenuCrm: false,
      hiddenHome: false,
      menuCrmWebTypeProjet: [
        {
          title: 'Type de projet',
          value: '2',
          icon: 'mdi-archive-plus-outline',
          url: '/projects',
          cleTraduction: 'typeProjet'
        }
      ],
      openTypeProjet: false
    }
  },
  methods: {
    ...mapActions(['fetchProjetsTypes', 'setCurentTypeProject']),
    langageTraduire(langage) {
      this.$emit('langage', langage)
    },
    openDashboard() {
      this.hiddenHome = true
      this.$emit('hiddenHome', this.hiddenHome)
    },
    flechBackTopNavBar() {
      this.hiddenMenuCrm = !this.hiddenMenuCrm
      this.$emit('hiddenMenuCrm', this.hiddenMenuCrm)
    },
    switchType(item) {
      this.setCurentTypeProject(item)
      if (this.$route && this.$route.name != 'projects') {
        this.$router.push('/projects')
      }
      this.openTypeProjet = false
    },
    async handleProjet() {
      if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
        await this.fetchProjetsTypes({
          exclude: ['fields'],
          loading: true
        })
      }
      this.openTypeProjet = true
    }
  },
  async mounted() {
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      await this.fetchProjetsTypes({
        exclude: ['fields'],
        loading: true
      })
    }

    if (localStorage.getItem('typeProject')) {
      if (this.getProjectsTypes && this.getProjectsTypes.length) {
        for (let index = 0; index < this.getProjectsTypes.length; index++) {
          if (
            this.getProjectsTypes[index] &&
            this.getProjectsTypes[index].id ==
              localStorage.getItem('typeProject')
          ) {
            this.setCurentTypeProject(this.getProjectsTypes[index])
          }
        }
      }
    } else {
      if (
        this.getProjectsTypes &&
        this.getProjectsTypes.length &&
        this.getProjectsTypes[0]
      ) {
        this.setCurentTypeProject(this.getProjectsTypes[0])
      }
    }
  },
  components: {
    DropDownNotification: () => import('./DropdownNotification.vue'),
    DropDownSetting: () => import('./DropdownSetting.vue'),
    DropDownSettingImmo: () => import('./DropdownSettingImmo.vue'),

    DropDownI18n: () => import('./DropDownI18n.vue'),
    DropDownl18nImmo: () => import('./DropDownl18nImmo.vue'),

    Search: () => import('@/components/CrmIla26Component/Search.vue'),
    SearchImmo: () => import('@/components/CrmIla26Component/SearchImmo.vue')

    // DropDownChat: () => import('./DropDownChat.vue')
    // Menu: () => import('./menu.vue')
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getProjectsTypesLoading',
      'getProjectsTypes',
      'getCurentProjectType',
      'getCurentProjectTypeImmo'
    ])
  }
}
</script>

<style lang="scss" scoped>
//---------------------------- Style pour projet Immo----------------------------------//
.menu-crm-web-project-Immo {
  display: flex;
  align-items: center;
  height: 73px;
  align-items: center;

  .theme--light.v-icon {
    color: white !important;
  }
  .menu-item {
    height: 37px;
    border-radius: 3px;
    background-color: rgb(0 0 0 / 2%);
    width: 100%;
    padding: 5px 7px 5px 7px;
    border: none;
    color: white;
    margin-top: 7px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 5px;
    a {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
      color: rgba(47, 47, 47, 1);
      &.router-link-exact-active {
        &.router-link-active {
          color: #fff !important;
        }
      }
    }
    &.active {
      background-color: rgba(58, 22, 127, 1);
      color: #fff !important;
      .color-title {
        color: #fff !important;
      }
      .icon-flech-menu {
        &:hover {
          border-radius: 20px;
          background-color: #f6f6f6;
          color: #3a167f;
        }
      }
      .icon-flech {
        color: #fff;
        &:hover {
          color: #3a167f;
        }
      }
      .count {
        background-color: #fff;
        color: #3a167f;
      }
    }
    .count {
      height: 23px;
      padding: 4px 6px 4px 6px;
      background-color: #3a167f;
      color: #fff;
      font-size: 11px;
      border-radius: 4px;
    }
    .icon-flech-menu {
      &:hover {
        border-radius: 20px;
        color: #3a167f;
        background-color: #fff;
      }
    }
    .color-active {
      color: #fff !important;
    }
  }
}
.nav-header-bar-Immo {
  position: fixed !important;
  background: #45148f !important;
  height: 56px !important;
}
.menu-corner-Immo {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  // height: 33px;
  // width: 100%;

  .Title-General-Energie {
    color: #ffffff;
    font-size: 13px;
    white-space: nowrap;
  }
  .icon {
    display: none;
  }
  .logoImmo {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    color: inherit;
    font-size: 1.75rem;
    transition: all 0.5s ease 0s;

    // display: flex;
    // font-weight: 800;
    // align-items: center;
    // margin: 0px 15px 0px -12px;
    // padding: 0 22px;
    // overflow: visible;
    // transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // padding-right: 11%;
    &.logo-arrow-left {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0px;
      border: 0px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      text-align: center;
      flex: 0 0 auto;
      border-radius: 50%;
      overflow: visible;
      color: inherit;
      padding: 12px;
      font-size: 1.75rem;
      transition: all 0.5s ease 0s;
    }
    img {
      width: 100px;
      height: 28px;
      margin-top: -6px;
    }
  }
}

.profile-corner-Immo {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 1200px) {
  .menu-corner {
    .icon {
      background-color: transparent;
      float: left;
      display: block;
      border: none;
      height: 37px;
      width: 37px;
      color: #4d4bac;
      border-radius: 5px;
      outline: 0;
      margin-top: 6px;
      &:hover {
        outline: 0;
        border-radius: 50%;
        background-color: rgba(219, 217, 217, 0.54);
      }
      .bar-icon {
        outline: 0;
      }
    }
    .menuTop {
      display: none;
      width: 0px;
    }
  }
}
.date-picker-export-globale {
  width: 100%;
}
.top-margin {
  margin-top: -5px;
  .btn-sidebar {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    padding: 8px;
    overflow: visible;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(92, 45, 211);
    border-radius: 5px;
    height: 40px;
    width: 40px;
    &:hover {
      background: rgb(92, 45, 211);
      .color-icon-mdi {
        color: white !important;
      }
    }
  }
}

//---------------------Style pour tous les projets sauF Immo--------------------------//
.menu-crm-web-project {
  display: flex;
  align-items: center;
  height: 73px;
  align-items: center;
  .menu-item {
    height: 37px;
    border-radius: 3px;
    background-color: rgb(0 0 0 / 2%);
    width: 100%;
    padding: 5px 7px 5px 7px;
    border: none;
    color: rgba(47, 47, 47, 1);
    margin: 3px;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 5px;
    a {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
      color: rgba(47, 47, 47, 1);
      &.router-link-exact-active {
        &.router-link-active {
          color: #fff !important;
        }
      }
    }
    &.active {
      background-color: rgba(58, 22, 127, 1);
      color: #fff !important;
      .color-title {
        color: #fff !important;
      }
      .icon-flech-menu {
        &:hover {
          border-radius: 20px;
          background-color: #f6f6f6;
          color: #3a167f;
        }
      }
      .icon-flech {
        color: #fff;
        &:hover {
          color: #3a167f;
        }
      }
      .count {
        background-color: #fff;
        color: #3a167f;
      }
    }
    .count {
      height: 23px;
      padding: 4px 6px 4px 6px;
      background-color: #3a167f;
      color: #fff;
      font-size: 11px;
      border-radius: 4px;
    }
    .icon-flech-menu {
      &:hover {
        border-radius: 20px;
        color: #3a167f;
        background-color: #fff;
      }
    }
    .color-active {
      color: #fff !important;
    }
  }
}
.nav-header-bar {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  position: fixed !important;
  background: rgb(255, 255, 255) !important;
  z-index: 10 !important;
  height: 64px !important;
  // background-color: #fff !important;
  // box-shadow: 0px 0px 0px 0px #d8d8d8, 0px 0px 4px 0px #d8d8d8,
  //   0px 0px 0px 0px #d8d8d8 !important;
}

.router-link,
.router-link-active {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.logo-crm {
  color: rgba(0, 0, 0, 1);
  padding-left: 10px;
  font-size: 20px;
  font-family: 'Gill Sans', sans-serif;
  letter-spacing: 0.5px;
  text-decoration: none;
  font-weight: 300;
  &:hover {
    text-decoration: none;
  }
}

.menu-corner {
  display: flex;
  align-items: center;
  // height: 33px;
  // width: 100%;
  .icon {
    display: none;
  }
  .logo {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px 15px 0px -12px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    color: inherit;
    padding: 12px;
    font-size: 1.75rem;
    transition: all 0.5s ease 0s;

    // display: flex;
    // font-weight: 800;
    // align-items: center;
    // margin: 0px 15px 0px -12px;
    // padding: 0 22px;
    // overflow: visible;
    // transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // padding-right: 11%;
    &.logo-arrow-left {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0px;
      border: 0px;
      margin: 0px 200px 0px -12px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      text-align: center;
      flex: 0 0 auto;
      border-radius: 50%;
      overflow: visible;
      color: inherit;
      padding: 12px;
      font-size: 1.75rem;
      transition: all 0.5s ease 0s;
    }
    img {
      width: 100px;
      height: 28px;
      margin-top: -6px;
    }
  }
}

.profile-corner {
  // float: right;
  // margin-top: 4px;
  float: right;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 1200px) {
  .menu-corner {
    .icon {
      background-color: transparent;
      float: left;
      display: block;
      border: none;
      height: 37px;
      width: 37px;
      color: #4d4bac;
      border-radius: 5px;
      outline: 0;
      margin-top: 6px;
      &:hover {
        outline: 0;
        border-radius: 50%;
        background-color: rgba(219, 217, 217, 0.54);
      }
      .bar-icon {
        outline: 0;
      }
    }
    .menuTop {
      display: none;
      width: 0px;
    }
  }
}
.date-picker-export-globale {
  width: 100%;
}
.top-margin {
  margin-top: -5px;
  .btn-sidebar {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    padding: 8px;
    overflow: visible;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(92, 45, 211);
    border-radius: 5px;
    height: 40px;
    width: 40px;
    &:hover {
      background: rgb(92, 45, 211);
      .color-icon-mdi {
        color: white !important;
      }
    }
  }
}
</style>
