<template>
  <div>
    <!----------------------------------------------Affichage pour projet IMMO --------------------------------------------- -->

    <div
      v-if="getCurentProjectTypeImmo"
      class="app-container"
      :class="{
        'app-container-theme-immo': getCurentProjectTypeImmo
      }"
    >
      <div class="home" :class="{ hidden: actionHiddenHome }">
        <div
          v-if="!onLine || showBackOnline"
          class="network-info"
          :class="{ online: showBackOnline, offline: !onLine }"
        >
          {{
            showBackOnline ? 'la connexion a été rétablie' : 'Pas de connexion'
          }}
        </div>
        <Topbar
          @hiddenMenuCrm="hiddenMenuCrm"
          @hiddenHome="hiddenHome"
          @langage="langageTraduire"
        />
        <div class="page-content-accueil-immo">
          <div class="menu-content" :class="{ hidden: mini }">
            <v-navigation-drawer class="nav-menu-crm" permanent>
              <v-list-item
                class="margin-start-title border-bottom-solid"
                :class="{ mini: mini }"
              >
                <div @click="mini = !mini" class="logo" style=" display: flex;">
                  <font-awesome-icon
                    :icon="['far', 'bars']"
                    class="mr-2 mt-1 ml-1"
                    style=" height: 19px;"
                  />
                  <router-link :to="'/'" v-if="!mini">
                    <img
                      src="@/assets/img/ilan26.png"
                      style="width: 80%; height: 100%; margin-left: 8px;"
                    />
                  </router-link>
                  <!-- <router-link :to="'/'" class="router-link">
                    <div class="logo-crm mb-2">CRM</div>
                  </router-link> -->
                </div>
              </v-list-item>
              <!-- PARTIE MODULE -->
              <v-list dense class="menu-crm" :class="{ mini: mini }">
                <!-- <h4 class="title-h4-1" v-if="!mini">
                  Modules
                </h4> -->
                <v-list-item
                  v-for="(item, index) in itemsImmo"
                  :key="item.title"
                  class="item-menu"
                  @click="activeMenu(index, item)"
                  :class="{ mini: mini, active: selectedItem == index }"
                  color="#5C2DD3"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <TableBord v-if="index == 0" class="color-image" />
                          <BusinessMap v-if="index == 1" class="color-image" />
                          <MarketPlace v-if="index == 2" class="color-image" />
                          <Bibliotheque v-if="index == 3" class="color-image" />
                          <GestionTache v-if="index == 4" class="color-image" />
                          <CalendrierPartage
                            v-if="index == 5"
                            class="color-image"
                          />
                          <Inbox v-if="index == 6" class="color-image" />
                          <BaseConnaissance
                            v-if="index == 7"
                            class="color-image"
                          />
                          <AnnuairePro v-if="index == 8" class="color-image" />
                          <Actualite v-if="index == 9" class="color-image" />
                          <Bullhorm v-if="index == 10" class="color-image" />
                          <Remonte v-if="index == 11" class="color-image" />
                          <Immobilier v-if="index == 12" class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span class="bold-500">{{
                      $t(`${item.cleTraduction}`)
                    }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <TableBord v-if="index == 0" class="color-image" />
                      <BusinessMap v-if="index == 1" class="color-image" />
                      <MarketPlace v-if="index == 2" class="color-image" />
                      <Bibliotheque v-if="index == 3" class="color-image" />
                      <GestionTache v-if="index == 4" class="color-image" />
                      <CalendrierPartage
                        v-if="index == 5"
                        class="color-image"
                      />
                      <Inbox v-if="index == 6" class="color-image" />
                      <BaseConnaissance v-if="index == 7" class="color-image" />
                      <AnnuairePro v-if="index == 8" class="color-image" />
                      <Actualite v-if="index == 9" class="color-image" />
                      <Bullhorm v-if="index == 10" class="color-image" />
                      <Remonte v-if="index == 11" class="color-image" />
                      <Immobilier v-if="index == 12" class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        class="text link  bold-400 font-sz-12 "
                      >
                        {{ $t(`${item.cleTraduction}`) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>

              <!-- <v-divider></v-divider> -->
              <!-- PARTIE PERSONNELS -->
              <!-- <v-list dense nav class="menu-crm-no-scroll"> -->
              <!-- <h4 class="title-h4-2" v-if="!mini">{{ $t('personnelle') }}</h4> -->
              <!-- <v-list-item
                  class="item-menu"
                  @click="activeMenu(13, '/')"
                  :class="{ mini: mini, active: selectedItem == 13 }"
                  color="#5C2DD3"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <PersoLite class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span> {{ $t('persoLite') }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <PersoLite class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text link bold-400 font-sz-12 "
                        >{{ $t('persoLite') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item> -->
              <!-- </v-list> -->
              <!-- PARTIE PARAMETRES -->
              <v-list dense nav class="menu-crm-no-scroll">
                <h4 class="title-h4-2" v-if="!mini">
                  {{ $t('lieudetravail') }}
                </h4>
                <v-list-item
                  class="item-menu"
                  color="#5C2DD3"
                  @click="activeMenu(14, '/')"
                  :class="{ mini: mini, active: selectedItem == 14 }"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <GestionTache class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span>{{ $t('tâches') }}</span>
                  </v-tooltip>

                  <template v-else>
                    <v-list-item-icon>
                      <GestionTache class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text link bold-400 font-sz-12 ">
                        {{ $t('tâches') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-list-item
                  class="item-menu"
                  @click="activeMenu(15, '/')"
                  :class="{ mini: mini, active: selectedItem == 15 }"
                  color="#5C2DD3"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <CalendrierPartage class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span>{{ $t('calendrierPartage') }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <CalendrierPartage class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text link bold-400 font-sz-12 "
                        >{{ $t('calendrierPartage') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-list-item
                  class="item-menu"
                  @click="activeMenu(15, '/')"
                  :class="{ mini: mini, active: selectedItem == 15 }"
                  color="#5C2DD3"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <MonProfil class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span>{{ $t('dashbord') }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <MonProfil class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text link bold-400 font-sz-12 "
                        >{{ $t('dashbord') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </div>
          <main>
            <div class="content" :class="{ hidden: mini }">
              <div class="content-global-crm">
                <!--  MENU ILA 26 -->
                <div class="header-content-global-crm">
                  <div
                    class="menu-crm-web mr-3"
                    :class="{
                      'ml-0': getCurentProjectTypeImmo,
                      'ml-3': !getCurentProjectTypeImmo
                    }"
                  >
                    <div
                      v-for="item in computedMenuCrmWeb"
                      :key="item.value"
                      class="menu-item block-flex-align-space-between"
                      @click="MenuWeb(item)"
                      :class="{ active: selectedMenuCrm == item.value }"
                    >
                      <div class="count mr-2" v-if="item && item.count != null">
                        {{ item.count }}
                      </div>
                      <div class="content-item-menu-immo">
                        <font-awesome-icon
                          :icon="item.icon"
                          class="icon-menu-immo"
                        ></font-awesome-icon>
                        <span>
                          {{
                            item.value == 4
                              ? $t(`${selectedMenuSAV.cleTraduction}`)
                              : $t(`${item.cleTraduction}`)
                          }}</span
                        >
                      </div>

                      <!-- SAV -->
                      <v-menu
                        offset-y
                        v-model="openSav"
                        origin="center center"
                        transition="scale-transition"
                        v-if="item.value == 4"
                        close-on-content-click
                        :nudge-bottom="8"
                        :nudge-left="115"
                        :nudge-width="200"
                      >
                        <template v-slot:activator="{ attrs, on }">
                          <div v-bind="attrs" v-on="on" class="icon-flech-menu">
                            <v-progress-circular
                              v-if="false"
                              indeterminate
                              color="#5C2DD3"
                              class="sz-loader-icon"
                            ></v-progress-circular>
                            <v-icon class="icon-flech" v-else
                              >mdi-chevron-down</v-icon
                            >
                          </div>
                        </template>
                        <v-list class="list-type-projet-scroll">
                          <v-list-item
                            v-for="(item, index) in menuGlobalSav"
                            :key="index"
                            class="list-type-projet-item"
                            @click.prevent.stop="handelMenuSav(item)"
                            :class="{
                              active: item.value == selectedMenuSAV.value
                            }"
                            link
                          >
                            <v-list-item-title
                              class=" list-type-onglet-projet-accueil block-flex-align-space-between"
                            >
                              <div
                                class="font-text font-sz-12 bold-700 title-type-popover"
                              >
                                {{ $t(`${item.cleTraduction}`) }}
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                  <div class="block-flex-align-space-between">
                    <!------------------------- Button Ajout Projet- Contact - opportunity ----------------->
                    <div >
                      <add-component></add-component> 
                    </div>
                    <div
                      v-if="
                        (currentUser && currentUser.isSuper) || computedRegie
                      "
                      class="config ml-2 mr-3 cursor-pointer"
                      :class="{ 'solid-color': selectedMenuCrm == 5 }"
                      @click.prevent.stop="ongletConfiguration"
                      :title="$t('parametres')"
                    >
                      <router-link to="/setting">
                        <v-icon class="color-icon">mdi-cog-outline</v-icon>
                        <div class="title-setting">{{ $t('parametres') }}</div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!-- ONGLET PROJET -->
                <router-view :typeProjectIdWithList="getCurentProjectType" />
              </div>
            </div>
          </main>
        </div>
      </div>
      <div
        v-if="actionHiddenHome"
        class="acceuil"
        @click="closeDashboard"
        ref="pageAccueil"
      >
        <div class="block-flex-align-space-between width-100 pt-3">
          <div class="title-dashboard">Modules business</div>
          <v-btn
            class="btn-close-header"
            icon
            :title="$t('close')"
            @click="closeDashboard"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider class="hr-line"></v-divider>
        <div class="body-dashboard">
          <div v-for="(item, index) in computedItems" :key="index" class="item">
            <router-link class="route-link" :to="item.url">
              <Dashboard>
                <template #image>
                  <!-- <TableBord v-if="index == 0" class="color-image" /> -->
                  <BusinessMap v-if="index == 0" class="color-image"/>
                  <MarketPlace v-if="index == 1" class="color-image"/>
                  <Bibliotheque v-if="index == 2" class="color-image"/>
                  <GestionTache v-if="index == 3" class="color-image"/>
                  <CalendrierPartage v-if="index == 4" class="color-image"/>
                  <Inbox v-if="index == 5" class="color-image"/>
                  <BaseConnaissance v-if="index == 6" class="color-image"/>
                  <AnnuairePro v-if="index == 7" class="color-image"/>
                  <Actualite v-if="index == 8" class="color-image"/>
                  <Bullhorm v-if="index == 9" class="color-image"/>
                  <Remonte v-if="index == 10" class="color-image"/>
                  <Immobilier v-if="index == 11" class="color-image"
                /></template>
                <template #title>{{ $t(`${item.cleTraduction}`) }}</template>
                <template #body>{{ $t('textSidebar') }}</template>
              </Dashboard>
            </router-link>
          </div>
        </div>
        <div class="block-flex-align-space-between width-100 ">
          <div class="title-dashboard">Modules perso</div>
        </div>
        <v-divider class="hr-line"></v-divider>
        <div class="body-dashboard">
          <div class="item">
            <!-- <router-link class="route-link" to="/">
              <Dashboard>
                <template #image> <PersoLite /></template>
                <template #title>{{ $t('persoLite') }}</template>
                <template #body>{{ $t('textSidebar') }}</template>
              </Dashboard>
            </router-link> -->
          </div>
        </div>
        <div class="block-flex-align-space-between width-100 ">
          <div class="title-dashboard">{{ $t('parametres') }}</div>
        </div>
        <v-divider class="hr-line"></v-divider>
        <div class="body-dashboard">
          <div v-for="(item, index) in menuParams" :key="index" class="item">
            <router-link class="route-link" :to="item.url">
              <Dashboard>
                <template #image>
                  <DemandeSupport class="color-image" v-if="index == 0" />
                  <MonProfil class="color-image" v-if="index == 1" />
                </template>
                <template #title>{{ $t(`${item.cleTraduction}`) }}</template>
                <template #body>{{ $t('textSidebar') }}</template>
              </Dashboard>
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="getLoaderThemeForProject" class="custom-full-loader">
        <v-progress-circular
          indeterminate
          color="#3a167f"
        ></v-progress-circular>
      </div>
    </div>

    <!----------------------------------------------Affichage pour tout les projects sauf IMMO --------------------------------------------- -->

    <div
      v-else
      class="app-container"
      :class="{
        'app-container-theme-immo': getCurentProjectTypeImmo
      }"
    >
      <div class="home" :class="{ hidden: actionHiddenHome }">
        <div
          v-if="!onLine || showBackOnline"
          class="network-info"
          :class="{ online: showBackOnline, offline: !onLine }"
        >
          {{
            showBackOnline ? 'la connexion a été rétablie' : 'Pas de connexion'
          }}
        </div>
        <Topbar
          @hiddenMenuCrm="hiddenMenuCrm"
          @hiddenHome="hiddenHome"
          @langage="langageTraduire"
        />
        <div class="page-content-accueil">
          <div class="menu-content" :class="{ hidden: mini }">
            <v-navigation-drawer class="nav-menu-crm" permanent>
              <v-list-item
                class="margin-start-title border-bottom-solid"
                :class="{ mini: mini }"
              >
                <v-tooltip
                  right
                  v-if="mini"
                  content-class="tootltip-home"
                  z-index="10"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs" class="block-icon">
                      <v-list-item-avatar v-if="mini" class="avatar-icon">
                        <font-awesome-icon
                          class="color-crm cursor-pointer ml-2 "
                          :class="{ mini: mini }"
                          :icon="['fas', 'badge-check']"
                        />
                      </v-list-item-avatar>
                    </div>
                  </template>
                  <span>GENERALE FRANCE ENERGIE</span>
                </v-tooltip>

                <v-list-item-content class="title-crm" v-else>
                  <v-list-item-subtitle
                    class="color-crm font-sz-12 mb-0 bold-600 font-text "
                  >
                    <span class=""> {{ $t('entreprise') }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-text font-sz-16 bold-title flex-center"
                  >
                    <span class="title-cdg">GENERALE FRANCE ENERGIE</span>
                    <span>
                      <font-awesome-icon
                        class="color-crm cursor-pointer img-h-20"
                        :icon="['fas', 'badge-check']"
                      />
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- PARTIE MODULE -->
              <v-list dense class="menu-crm" :class="{ mini: mini }">
                <h4 class="title-h4-1" v-if="!mini">
                  Modules
                </h4>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="item.title"
                  class="item-menu"
                  @click="activeMenu(index, item)"
                  :class="{ mini: mini, active: selectedItem == index }"
                  color="#5C2DD3"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <TableBord v-if="index == 0" class="color-image" />
                          <BusinessMap v-if="index == 1" class="color-image" />
                          <MarketPlace v-if="index == 2" class="color-image" />
                          <Bibliotheque v-if="index == 3" class="color-image" />
                          <GestionTache v-if="index == 4" class="color-image" />
                          <CalendrierPartage
                            v-if="index == 5"
                            class="color-image"
                          />
                          <Inbox v-if="index == 6" class="color-image" />
                          <BaseConnaissance
                            v-if="index == 7"
                            class="color-image"
                          />
                          <AnnuairePro v-if="index == 8" class="color-image" />
                          <Actualite v-if="index == 9" class="color-image" />
                          <Bullhorm v-if="index == 10" class="color-image" />
                          <Remonte v-if="index == 11" class="color-image" />
                          <Immobilier v-if="index == 12" class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span class="bold-500">{{
                      $t(`${item.cleTraduction}`)
                    }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <TableBord v-if="index == 0" class="color-image" />
                      <BusinessMap v-if="index == 1" class="color-image" />
                      <MarketPlace v-if="index == 2" class="color-image" />
                      <Bibliotheque v-if="index == 3" class="color-image" />
                      <GestionTache v-if="index == 4" class="color-image" />
                      <CalendrierPartage
                        v-if="index == 5"
                        class="color-image"
                      />
                      <Inbox v-if="index == 6" class="color-image" />
                      <BaseConnaissance v-if="index == 7" class="color-image" />
                      <AnnuairePro v-if="index == 8" class="color-image" />
                      <Actualite v-if="index == 9" class="color-image" />
                      <Bullhorm v-if="index == 10" class="color-image" />
                      <Remonte v-if="index == 11" class="color-image" />
                      <Immobilier v-if="index == 12" class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        class="text link  bold-400 font-sz-12 "
                      >
                        {{ $t(`${item.cleTraduction}`) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>

              <!-- <v-divider></v-divider> -->
              <!-- PARTIE PERSONNELS -->
              <v-list dense nav class="menu-crm-no-scroll">
                <h4 class="title-h4-2" v-if="!mini">{{ $t('personnelle') }}</h4>
                <v-list-item
                  class="item-menu"
                  @click="activeMenu(13, '/')"
                  :class="{ mini: mini, active: selectedItem == 13 }"
                  color="#5C2DD3"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <PersoLite class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span> {{ $t('persoLite') }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <PersoLite class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text link bold-400 font-sz-12 "
                        >{{ $t('persoLite') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
              <!-- PARTIE PARAMETRES -->
              <v-list dense nav class="menu-crm-no-scroll">
                <h4 class="title-h4-2" v-if="!mini">{{ $t('parametres') }}</h4>
                <v-list-item
                  class="item-menu"
                  color="#5C2DD3"
                  @click="activeMenu(14, '/')"
                  :class="{ mini: mini, active: selectedItem == 14 }"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <DemandeSupport class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span>{{ $t('demandeSupport') }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <DemandeSupport class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text link bold-400 font-sz-12 ">
                        {{ $t('demandeSupport') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-list-item
                  class="item-menu"
                  @click="activeMenu(15, '/')"
                  :class="{ mini: mini, active: selectedItem == 15 }"
                  color="#5C2DD3"
                >
                  <v-tooltip
                    right
                    v-if="mini"
                    content-class="tootltip-home"
                    z-index="10"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <MonProfil class="color-image" />
                        </v-list-item-icon>
                      </div>
                    </template>
                    <span>{{ $t('monProfil') }}</span>
                  </v-tooltip>
                  <template v-else>
                    <v-list-item-icon>
                      <MonProfil class="color-image" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text link bold-400 font-sz-12 "
                        >{{ $t('monProfil') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </div>
          <main>
            <div
              class="content"
              :class="{ hidden: valueHiddenMenuCrm && hiddenMenuCrmClick }"
            >
              <div class="content-global-crm">
                <!--  MENU ILA 26 -->
                <div class="header-content-global-crm">
                  <div
                    class="menu-crm-web mr-3"
                    :class="{
                      'ml-0': getCurentProjectTypeImmo,
                      'ml-3': !getCurentProjectTypeImmo
                    }"
                  >
                    <div
                      v-for="item in computedMenuCrmWeb"
                      :key="item.value"
                      class="menu-item block-flex-align-space-between"
                      @click="MenuWeb(item)"
                      :class="{ active: selectedMenuCrm == item.value }"
                    >
                      <div class="count mr-2" v-if="item && item.count != null">
                        {{ item.count }}
                      </div>
                      <div class=" ontent-item-menu-imm">
                        <font-awesome-icon
                          :icon="item.icon"
                          class="icon-menu-immo"
                        ></font-awesome-icon>
                        <span>
                          {{
                            item.value == 4
                              ? $t(`${selectedMenuSAV.cleTraduction}`)
                              : $t(`${item.cleTraduction}`)
                          }}</span
                        >
                      </div>

                      <!-- SAV -->
                      <v-menu
                        offset-y
                        v-model="openSav"
                        origin="center center"
                        transition="scale-transition"
                        v-if="item.value == 4"
                        close-on-content-click
                        :nudge-bottom="8"
                        :nudge-left="115"
                        :nudge-width="200"
                      >
                        <template v-slot:activator="{ attrs, on }">
                          <div v-bind="attrs" v-on="on" class="icon-flech-menu">
                            <v-progress-circular
                              v-if="false"
                              indeterminate
                              color="#5C2DD3"
                              class="sz-loader-icon"
                            ></v-progress-circular>
                            <v-icon class="icon-flech" v-else
                              >mdi-chevron-down</v-icon
                            >
                          </div>
                        </template>
                        <v-list class="list-type-projet-scroll">
                          <v-list-item
                            v-for="(item, index) in menuGlobalSav"
                            :key="index"
                            class="list-type-projet-item"
                            @click.prevent.stop="handelMenuSav(item)"
                            :class="{
                              active: item.value == selectedMenuSAV.value
                            }"
                            link
                          >
                            <v-list-item-title
                              class=" list-type-onglet-projet-accueil block-flex-align-space-between"
                            >
                              <div
                                class="font-text font-sz-12 bold-700 title-type-popover"
                              >
                                {{ $t(`${item.cleTraduction}`) }}
                              </div>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>

                  <!---------------------- Button ajout nv Project ------------------>
                  <div class="block-flex-align-space-between">
                    <div>
                      <add-component></add-component>
                    </div>
                    <div
                      v-if="
                        (currentUser && currentUser.isSuper) || computedRegie
                      "
                      class="config ml-2 mr-3 cursor-pointer"
                      :class="{ 'solid-color': selectedMenuCrm == 5 }"
                      @click.prevent.stop="ongletConfiguration"
                      :title="$t('parametres')"
                    >
                      <router-link to="/setting">
                        <v-icon class="color-icon">mdi-cog-outline</v-icon>
                        <div class="title-setting">{{ $t('parametres') }}</div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!-- ONGLET PROJET -->
                <router-view :typeProjectIdWithList="getCurentProjectType" />
              </div>
            </div>
          </main>
        </div>
      </div>
      <div
        v-if="actionHiddenHome"
        class="acceuil"
        @click="closeDashboard"
        ref="pageAccueil"
      >
        <div class="block-flex-align-space-between width-100 pt-3">
          <div class="title-dashboard">Modules business</div>
          <v-btn
            class="btn-close-header"
            icon
            :title="$t('close')"
            @click="closeDashboard"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider class="hr-line"></v-divider>
        <div class="body-dashboard">
          <div v-for="(item, index) in computedItems" :key="index" class="item">
            <router-link class="route-link" :to="item.url">
              <Dashboard>
                <template #image>
                  <!-- <TableBord v-if="index == 0" class="color-image" /> -->
                  <BusinessMap v-if="index == 0" class="color-image"/>
                  <MarketPlace v-if="index == 1" class="color-image"/>
                  <Bibliotheque v-if="index == 2" class="color-image"/>
                  <GestionTache v-if="index == 3" class="color-image"/>
                  <CalendrierPartage v-if="index == 4" class="color-image"/>
                  <Inbox v-if="index == 5" class="color-image"/>
                  <BaseConnaissance v-if="index == 6" class="color-image"/>
                  <AnnuairePro v-if="index == 7" class="color-image"/>
                  <Actualite v-if="index == 8" class="color-image"/>
                  <Bullhorm v-if="index == 9" class="color-image"/>
                  <Remonte v-if="index == 10" class="color-image"/>
                  <Immobilier v-if="index == 11" class="color-image"
                /></template>
                <template #title>{{ $t(`${item.cleTraduction}`) }}</template>
                <template #body>{{ $t('textSidebar') }}</template>
              </Dashboard>
            </router-link>
          </div>
        </div>
        <div class="block-flex-align-space-between width-100 ">
          <div class="title-dashboard">Modules perso</div>
        </div>
        <v-divider class="hr-line"></v-divider>
        <div class="body-dashboard">
          <div class="item">
            <router-link class="route-link" to="/">
              <Dashboard>
                <template #image> <PersoLite /></template>
                <template #title>{{ $t('persoLite') }}</template>
                <template #body>{{ $t('textSidebar') }}</template>
              </Dashboard>
            </router-link>
          </div>
        </div>
        <div class="block-flex-align-space-between width-100 ">
          <div class="title-dashboard">{{ $t('parametres') }}</div>
        </div>
        <v-divider class="hr-line"></v-divider>
        <div class="body-dashboard">
          <div v-for="(item, index) in menuParams" :key="index" class="item">
            <router-link class="route-link" :to="item.url">
              <Dashboard>
                <template #image>
                  <DemandeSupport class="color-image" v-if="index == 0" />
                  <MonProfil class="color-image" v-if="index == 1" />
                </template>
                <template #title>{{ $t(`${item.cleTraduction}`) }}</template>
                <template #body>{{ $t('textSidebar') }}</template>
              </Dashboard>
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="getLoaderThemeForProject" class="custom-full-loader">
        <v-progress-circular
          indeterminate
          color="#3a167f"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
// import Topbar from '@/components/Topbar'
import Topbar from '@/components/topNavBar/Topbar'
import { mapActions, mapGetters } from 'vuex'
import AddComponent from './CRMILA26/Projects/Dialogs/addComponent.vue'

export default {
  name: 'App',
  components: {
    Topbar,
    Dashboard: () => import('@/components/CrmIla26Component/Dashboard.vue'),
    BusinessMap: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/BusinessMap.vue'
      ),
    TableBord: () =>
      import('@/components/CrmIla26Component/ImgSideBarCRMIla26/TableBord.vue'),
    MarketPlace: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/MarketPlace.vue'
      ),
    Bibliotheque: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/Bibliotheque.vue'
      ),
    GestionTache: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/GestionTache.vue'
      ),
    CalendrierPartage: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/CalendrierPartage.vue'
      ),
    Inbox: () =>
      import('@/components/CrmIla26Component/ImgSideBarCRMIla26/Inbox.vue'),
    BaseConnaissance: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/BaseConnaissance.vue'
      ),
    AnnuairePro: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/AnnuairePro.vue'
      ),
    Actualite: () =>
      import('@/components/CrmIla26Component/ImgSideBarCRMIla26/Actualite.vue'),
    Bullhorm: () =>
      import('@/components/CrmIla26Component/ImgSideBarCRMIla26/Bullhorm.vue'),
    Remonte: () =>
      import('@/components/CrmIla26Component/ImgSideBarCRMIla26/Remonte.vue'),
    Immobilier: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/Immobilier.vue'
      ),
    PersoLite: () =>
      import('@/components/CrmIla26Component/ImgSideBarCRMIla26/PersoLite.vue'),
    DemandeSupport: () =>
      import(
        '@/components/CrmIla26Component/ImgSideBarCRMIla26/DemandeSupport.vue'
      ),
    MonProfil: () =>
      import('@/components/CrmIla26Component/ImgSideBarCRMIla26/MonProfil.vue'),

    AddComponent,
  },
  data() {
    return {
      mini: false,
      onLine: navigator.onLine,
      showBackOnline: false,
      valueHiddenMenuCrm: true,
      hiddenMenuCrmClick: false,
      actionHiddenHome: false,
      selectedMenuCrm: null,
      selectedItem: 10,
      itemsImmo: [
        {
          title: 'CRM',
          icon: ['fal', 'table-columns'],
          permission: true,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          value: 0,
          cleTraduction: 'CRM'
        },
        {
          title: 'Business Map',
          icon: ['fal', 'megaphone'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'businessMap'
        },
        {
          title: 'Marketplace',
          icon: ['fal', 'address-book'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'marketplace'
        },
        {
          title: 'Bibliothèque',
          icon: ['fal', 'code-branch'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'bibliothèque'
        },

        {
          title: 'Gestion des taches',
          icon: ['fal', 'crosshairs-simple'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'gestionTache'
        },
        {
          title: 'Calendrier partagé',
          icon: ['fal', 'folder-open'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'calendrierPartage'
        },
        {
          title: 'Inbox',
          icon: ['fal', 'calendar-circle-plus'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'inbox'
        },
        {
          title: 'Base de connaissances',
          icon: ['fal', 'message-dots'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'baseConnaissances'
        },
        {
          title: 'Annuaire pro',
          icon: ['fal', 'map-location-dot'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'annuairePro'
        },
        {
          title: 'Actualités',
          icon: ['fal', 'bullhorn'],
          url: '/',
          permission: false,
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'actualites'
        },
        // {
        //   title: 'CRM Ila 26',
        //   icon: ['fal', 'bullhorn'],
        //   url: '/',
        //   permission: true,
        //   textSidebar:
        //     'Retrouvez les contacts de vos collègues et plus encore !',
        //   cleTraduction: 'crmIla26'
        // },
        {
          title: 'Remontées',
          icon: ['fal', 'store'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'remontées'
        },
        {
          title: 'Immobilier',
          icon: ['fal', 'store'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'immobilier'
        }
      ],
      items: [
        {
          title: 'Tableau de bord',
          icon: ['fal', 'table-columns'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          value: 0,
          cleTraduction: 'dashbord'
        },
        {
          title: 'Plan cadastral',
          icon: ['fal', 'megaphone'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'Property map'
        },
        {
          title: 'Marketing',
          icon: ['fal', 'address-book'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'Marketing'
        },
        {
          title: 'Bibliothèque',
          icon: ['fal', 'code-branch'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'Library'
        },

        {
          title: 'Gestion des taches',
          icon: ['fal', 'crosshairs-simple'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'gestionTache'
        },
        {
          title: 'Calendrier',
          icon: ['fal', 'folder-open'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'calendrierPartage'
        },
        {
          title: 'Inbox',
          icon: ['fal', 'calendar-circle-plus'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'inbox'
        },
        {
          title: 'Base de connaissances',
          icon: ['fal', 'message-dots'],
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'baseConnaissances'
        },
        {
          title: 'Annuaire pro',
          icon: ['fal', 'map-location-dot'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'annuairePro'
        },
        {
          title: 'Actualités',
          icon: ['fal', 'bullhorn'],
          url: '/',
          permission: true,
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'actualites'
        },
        {
          title: 'CRM Ila 26',
          icon: ['fal', 'bullhorn'],
          url: '/',
          permission: false,
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'crmIla26'
        },
        {
          title: 'Remontées',
          icon: ['fal', 'store'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'remontées'
        },
        {
          title: 'Immobilier',
          icon: ['fal', 'store'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'immobilier'
        }
      ],
      menuParams: [
        {
          title: 'Demande de support',
          icon: ['fal', 'store'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'demandeSupport'
        },
        {
          title: 'Mon Profil',
          icon: ['fal', 'store'],
          permission: false,
          url: '/',
          textSidebar:
            'Retrouvez les contacts de vos collègues et plus encore !',
          cleTraduction: 'monProfil'
        }
      ],
      selectedMenuSAV: {
        title: 'SAV',
        value: 1,
        url: '/sav',
        cleTraduction: 'sav'
      },
      menuGlobalSav: [
        {
          title: 'SAV',
          value: 1,
          url: '/sav',
          cleTraduction: 'sav'
        },
        {
          title: 'Services clients',
          value: 2,
          url: '/sav/service-clients',
          cleTraduction: 'servicesClients'
        }
      ],

      initLoadingComponent: false,
      openPlannings: false,
      openSav: false,
      openSetting: false
    }
  },
  methods: {
    ...mapActions([
      'fetchfillPipelinesTable',
      'fetchfillStagesTable',
      'fetchListNotification',
      'fetchProjetsTypes',
      'setLoaderThemeForProject',
      'fetchSingleProject'
    ]),

    langageTraduire(langage) {
      this.$i18n.locale = langage ? langage : 'fr'
    },
    handelMenuSav(item) {
      this.selectedMenuSAV = item
      this.openSav = !this.openSav
      if (item.value == 2) {
        this.$router.push('/sav/service-clients')
      } else {
        this.$router.push('/sav')
      }
    },
    activeMenu(index) {
      this.selectedItem = index
      if (this.$route && this.$route.path != '/') {
        this.$router.push('/')
      }
    },
    closeDashboard() {
      this.actionHiddenHome = false
    },
    ongletConfiguration() {
      this.selectedMenuCrm = 5
      if (this.$route && this.$route.name == 'users') {
        this.$router.replace('/setting').catch(() => {})
      }
      this.openSetting = true
    },

    MenuWeb(item) {
      if (item.url != '#') {
        this.selectedMenuCrm = item.value
        if (
          item.value == 4 &&
          this.selectedMenuSAV &&
          this.selectedMenuSAV.url
        ) {
          this.$router.replace(this.selectedMenuSAV.url).catch(() => {})
        } else {
          this.$router.replace({ path: item.url }).catch(() => {})
        }
      }
    },
    //////
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
    },
    hiddenHome(e) {
      this.actionHiddenHome = e
    },
    hiddenMenuCrm(e) {
      this.hiddenMenuCrmClick = true
      this.valueHiddenMenuCrm = e
      this.mini = e
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getContactsProcessing',
      'getProjectsProcessing',
      'getProjectsTypesLoading',
      'getProjectsTypes',
      'getCurentProjectType',
      'getCurentProjectTypeImmo',
      'getLoaderThemeForProject'
    ]),
    computedMenuCrmWeb() {
      let menuCrmWeb = [
        {
          title: 'Tableau de bord',
          value: 2,
          url: '/projects',
          cleTraduction: this.getCurentProjectTypeImmo
            ? 'pipeline'
            : 'dashbord',
          icon: ['fal', 'briefcase'],
          visible: 'all'
        },
        {
          title: 'Contacts',
          value: 6,
          url: '/contacts',
          cleTraduction: 'contact',
          icon: ['fal', 'user-group'],
          visible: 'immobilier'
        },
        {
          title: 'Planning',
          value: 3,
          url: '/planning',
          cleTraduction: this.getCurentProjectTypeImmo
            ? 'calendar_task'
            : 'planning',
          icon: ['fal', 'calendar-days'],
          visible: 'all'
        },
        {
          title: 'Catalog',
          value: 7,
          url: '/catalog',
          cleTraduction: 'catalog',
          icon: ['fal', 'folder'],
          visible: 'immobilier'
        },
        {
          title: 'SAV',
          value: 4,
          count: 0,
          url: '/sav',
          cleTraduction: 'sav',
          icon: ['fal', 'folder'],
          visible: 'par défaut'
        },
        {
          title: 'dashboard',
          value: 8,
          url: '#',
          cleTraduction: 'dashboard',
          icon: ['fal', 'grid-2'],
          visible: 'immobilier'
        }
      ]
      return this.getCurentProjectType && this.getCurentProjectType.theme
        ? menuCrmWeb.filter(
            i =>
              i.visible == 'all' || i.visible == this.getCurentProjectType.theme
          )
        : menuCrmWeb.filter(
            i => i.visible == 'all' || i.visible == 'par défaut'
          )
    },
    computedItems: function() {
      return this.items.filter(item => item && item.value != 0)
    },
    computedRegie: function() {
      return (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'regie'))
      )
    }
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true
        setTimeout(() => {
          this.showBackOnline = false
        }, 2000)
      }
    },
    $route(route) {
      if (route && (route.name == 'projects' || route.name == 'project')) {
        this.selectedMenuCrm = 2
      } else if (route && route.name == 'client') {
        this.selectedMenuCrm = 1
      } else if (route && route.name == 'planning') {
        this.selectedMenuCrm = 3
      } else if (route && route.name == 'catalog') {
        this.selectedMenuCrm = 7
      } else if (route && route.name == 'contacts') {
        this.selectedMenuCrm = 6
      } else if (route && route.name == 'table-bord') {
        this.selectedMenuCrm = 0
      } else if (route && route.name == 'sav') {
        this.selectedMenuCrm = 4
        this.selectedMenuSAV = {
          title: 'SAV',
          value: 1,
          url: '/sav',
          cleTraduction: 'sav'
        }
      } else if (route && route.name == 'service-clients') {
        this.selectedMenuCrm = 4
        this.selectedMenuSAV = {
          title: 'Services clients',
          value: 2,
          url: '/sav/service-clients',
          cleTraduction: 'servicesClients'
        }
      } else if (
        route &&
        route.matched &&
        route.matched.length &&
        route.matched[1] &&
        route.matched[1].path &&
        route.matched[1].path == '/setting'
      ) {
        this.selectedMenuCrm = 5
      } else {
        this.selectedMenuCrm = null
      }
    }
  },
  async mounted() {
    if (
      this.$route.name == 'project' &&
      this.$route.params &&
      this.$route.params.id
    ) {
      this.setLoaderThemeForProject(true)
    }
    const langage = localStorage.getItem('i18n')
    if (langage) {
      this.$i18n.locale = langage
    } else {
      this.$i18n.locale = 'fr' // Par défaut, si aucune valeur n'est trouvée dans le localStorage
    }
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    this.fetchListNotification(this.currentUser.id)
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      await this.fetchProjetsTypes({
        exclude: ['fields'],
        loading: true
      })
    }
    if (
      this.$route &&
      (this.$route.name == 'projects' || this.$route.name == 'project')
    ) {
      this.selectedMenuCrm = 2
    } else if (this.$route && this.$route.name == 'client') {
      this.selectedMenuCrm = 1
    } else if (this.$route && this.$route.name == 'planning') {
      this.selectedMenuCrm = 3
    } else if (this.$route && this.$route.name == 'catalog') {
      this.selectedMenuCrm = 7
    } else if (this.$route && this.$route.name == 'contacts') {
      this.selectedMenuCrm = 6
    } else if (this.$route && this.$route.name == 'table-bord') {
      this.selectedMenuCrm = 0
    } else if (this.$route && this.$route.name == 'sav') {
      this.selectedMenuCrm = 4
      this.selectedMenuSAV = {
        title: 'SAV',
        value: 1,
        url: '/sav',
        cleTraduction: 'sav'
      }
    } else if (this.$route && this.$route.name == 'service-clients') {
      this.selectedMenuCrm = 4
      this.selectedMenuSAV = {
        title: 'Services clients',
        value: 2,
        url: '/sav/service-clients',
        cleTraduction: 'servicesClients'
      }
    } else if (
      this.$route &&
      this.$route.matched &&
      this.$route.matched.length &&
      this.$route.matched[1] &&
      this.$route.matched[1].path &&
      this.$route.matched[1].path == '/setting'
    ) {
      this.selectedMenuCrm = 5
    } else {
      this.selectedMenuCrm = null
    }
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  .icon-menu-immo {
    display: none;
  }
  .home {
    display: block;

    &.hidden {
      display: none;
    }
  }
  .acceuil {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    // height: 100%;
    max-height: calc(100vh - 0px) !important;
    height: calc(100vh - 0px) !important;

    overflow: auto;
    padding: 16px;
    gap: 8px;
    // margin-top: 32px;
    background: rgb(250, 250, 250);
    .body-dashboard {
      display: flex;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      align-items: center;
      flex-flow: wrap;
      width: 90%;
      gap: 15px;
      margin: 0px auto;
      padding: 16px;
      .item {
        display: flex;
        flex: 0 0 250px;
        opacity: 1;
        .route-link {
          all: unset;
        }
      }
    }

    .title-dashboard {
      margin: 0px;
      font-size: 18px;
      font-weight: 700;

      line-height: 1.235;
      color: rgb(33, 33, 33);
      width: 90%;
      text-align: left;
      // padding-left: 16px;
      padding-left: 79px;
      padding-right: 16px;
    }
    .hr-line {
      margin: 0px 0px 0px 0px !important;
      flex-shrink: 0;
      border-width: 0px 0px thin;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.12);
      width: 100%;
    }
  }
  hr {
    margin: 0px 0px 4px 0px !important;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    width: 100%;
  }
  .acceuil::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .acceuil::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .acceuil::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
    cursor: pointer !important;
  }
  main {
    // display: block;
    // position: relative;
    // height: 100%;
    // overflow-y: auto;
    // box-sizing: border-box;
    // margin-top: 48px;
    // height: calc(100% - 48px);
    margin-top: 56px;
    height: calc(100% - 64px);
  }
  .network-info {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    color: #fff;
    text-align: center;
    padding: 5px 0;

    &.offline {
      background-color: #ee220c;
    }

    &.online {
      background-color: #28a745;
    }
  }
  .img-width {
    width: 20px;
  }
}
.app-container-theme-immo {
  .icon-menu-immo {
    display: inline !important;
    height: 1.2rem !important;
  }
  .margin-start-title .title-crm {
    padding: 0.5rem 0px 0.1rem 1rem !important;
  }
  .margin-start-title .block-icon {
    padding: 0px !important;

    .avatar-icon {
      height: 35px !important;
    }
  }
  .menu-crm-web {
    height: 51px !important;
    margin-bottom: -1px;
    .menu-item {
      background-color: transparent !important;
      padding: 7px 11px 2px 11px !important;
      border: none !important;
      font-weight: 600 !important;
      font-size: 12px !important;
      margin: 0px !important;
      text-transform: uppercase;
      cursor: pointer;
      letter-spacing: 0px;
      border-bottom: 3px solid transparent !important;
      border-radius: 0px !important;
      height: 100% !important;
      &:hover {
        color: #5c2dd3 !important;
      }
    }
    .active {
      color: #5c2dd3 !important;
      border-bottom: 4px solid #5c2dd3 !important;
    }
  }
  .config {
    border: 0px !important;
    color: #2f2f2f !important;
    &:hover {
      background-color: transparent !important;
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .color-icon {
      color: #2f2f2f !important;
      font-size: 20px !important;
    }
    .title-setting {
      display: block !important;
      color: #2f2f2f !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      margin-left: 3px !important;
      margin-top: 2px !important;
    }
  }
  .solid-color {
    background-color: transparent !important;
    .color-icon {
      background-color: transparent !important;
    }
  }
}
</style>
<style lang="scss" scoped>
// Style affichage Immo
.page-content-accueil-immo {
  position: relative;
  // padding: 16px 0px 16px 16px;
  padding: 0px;
  // height: 100%;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  .content-item-menu-immo {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .menu-content {
    display: block;
    position: absolute;
    width: 260px;
    height: 94%;
    padding: 0px;
    transform: translateX(0px);
    transition: 0.5s all ease;
    transition-timing-function: ease-out;
    &.hidden {
      width: 60px !important;
      // border-right: 1px solid rgb(216, 216, 216);
      height: calc(100% - 64px);
      position: fixed;
      transition: all 0.3s ease 0s;
    }
  }
  .bold-title {
    font-weight: 700;

    font-size: 16px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    padding: 0px;
    margin: 0px;
    margin-top: 2px;
    margin-block-end: 5px;
  }
  .title-item-nav-footer {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;

    .v-list-item__icon {
      -ms-flex-item-align: start;
      align-self: flex-start;
      margin: 10px 14px;
      min-width: 19px;
      height: 25px;
    }
    // .v-list-item__icon:first-child {
    //   margin-right: 20px;
    // }
  }
  // .margin-title {
  //   margin-top: -5px;
  // }
  .margin-title-config {
    margin-top: 5px;
  }
  .nav-menu-crm {
    overflow: hidden !important;
    width: 325px !important;
    .menu-crm {
      // display: flex;
      // justify-content: center;
      // flex-direction: column;
      // flex: 1 1 100px;
      // padding-left: 1rem;
      // // padding-top: 2rem;
      // padding-bottom: 1rem;
      // padding-right: 2rem;
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      -webkit-box-flex: 1;
      flex: 1 1 100px;
      padding-left: 1rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
      position: relative;
      overflow-y: auto !important;
      overflow-x: hidden !important;
      flex-wrap: nowrap !important;
      max-height: calc(100vh - 315px) !important;
      height: calc(100vh - 315px) !important;
      &.mini {
        max-height: calc(100vh - 355px) !important;
        height: calc(100vh - 355px) !important;
        padding-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
    .menu-crm-no-scroll {
      //  display: flex;
      // justify-content: center;
      // flex-direction: column;
      // flex: 1 1 100px;
      // padding-left: 1rem;
      // padding-top: 2rem;
      // padding-bottom: 1rem;
      // padding-right: 2rem;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 1px solid rgb(229, 229, 229);
      // position: relative;
      // overflow-y: auto !important;
      // overflow-x: hidden !important;
      // flex-wrap: nowrap !important;
      // // padding: 14px 18px;
      // -webkit-box-pack: center;
      // justify-content: center;
      // flex-direction: column;
      // -webkit-box-flex: 1;
      // flex: 1 1 100px;
      // padding-left: 2rem;
      // padding-top: 0rem;
      // padding-bottom: 0rem;
      // position: relative;
      // overflow-y: auto !important;
      // overflow-x: hidden !important;
      // flex-wrap: nowrap !important;
    }
    .menu-crm,
    .menu-params,
    .menu-crm-no-scroll {
      .item-menu {
        margin-bottom: 2px !important;
        min-height: 40px !important;
        display: flex;
        align-items: center;
        white-space: nowrap;
        width: 266px;
        margin-inline-start: -15px;
        padding: 0px 16px !important;
        border-radius: 6px;
        &.mini {
          width: 58px;
        }
        .link {
          a {
            overflow: hidden;
            font-weight: 700 !important;
            font-size: 12px !important;
            line-height: 1.5;
            white-space: nowrap;
            text-decoration: none;
            color: rgba(47, 47, 47, 1);

            letter-spacing: 0.4px;
            &.router-link-exact-active {
              &.router-link-active {
                color: rgba(47, 47, 47, 1);
                font-weight: 500;
                letter-spacing: 0.4px;
              }
            }
          }
        }
        .v-list-item__icon {
          margin-right: 14px !important;
          gap: 10px;
          height: 27px !important;
        }

        &.active {
          background: rgba(92, 45, 211, 0.1);
          border-left: 4px solid #3a167f; /* Ajout de la bordure à gauche */
          color: rgb(92, 45, 211);
          border-radius: 0px;
          width: 266px;
          margin-inline-start: -15px;
          &.mini {
            width: 58px;
          }
          .color-image {
            filter: brightness(0) saturate(100%) invert(28%) sepia(43%)
              saturate(3767%) hue-rotate(245deg) brightness(89%) contrast(83%);
          }
          .color-icon-current {
            color: #5c2dd3 !important;
          }
          .text {
            font-weight: 700 !important;
            font-size: 12px !important;
            color: #5c2dd3 !important;
            line-height: 1.5 !important;

            letter-spacing: 0.4px;
          }

          .link {
            a {
              background: rgba(92, 45, 211, 0.1);
              color: rgb(92, 45, 211);
              border-radius: 6px;
              overflow: hidden;
              font-weight: 700;
              font-size: 12px;
              line-height: 19.5px;
              white-space: nowrap;
              text-decoration: none;
              // color: #5C2DD3 !important;

              letter-spacing: 0.4px;
              &.router-link-exact-active {
                &.router-link-active {
                  color: #5c2dd3 !important;
                  font-weight: 700;
                  letter-spacing: 0.4px;
                  font-size: 12px;
                }
              }
            }
          }
        }
        &:hover {
          background: rgba(92, 45, 211, 0.1) !important;
          color: rgb(92, 45, 211) !important;
          border-radius: 6px !important;
          .color-image {
            filter: brightness(0) saturate(100%) invert(28%) sepia(43%)
              saturate(3767%) hue-rotate(245deg) brightness(89%) contrast(83%);
          }
          .color-icon-current {
            color: rgb(92, 45, 211) !important;
            font-size: 12px;

            letter-spacing: 0.4px;
          }
          .text {
            color: rgb(92, 45, 211) !important;
            font-weight: 700 !important;
            font-size: 12px !important;

            letter-spacing: 0.4px;
          }
          .v-list-item__icon {
            color: rgb(92, 45, 211) !important;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
    }
    .title-h4-1 {
      font-weight: 700;

      line-height: 1.235;
      text-transform: uppercase;
      margin: 0px 0px 1px -15px;
      letter-spacing: 0.4px;
      font-size: 14px;
      color: rgb(47, 47, 47);
      position: sticky;
      top: 0rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 15px;
      background-color: #ffffff;
      z-index: 2;
    }
    .title-h4-2 {
      font-weight: 500;

      line-height: 24px;
      text-transform: uppercase;
      margin: 0px 0px 18px -15px;
      letter-spacing: 0.4px;
      font-size: 12px;
      color: #505050;
      position: sticky;
      top: 0rem;
      // padding-top: 1rem;
      // padding-bottom: 1rem;
      padding-left: 15px;
      background-color: #ffffff;
      z-index: 2;
    }
    // .params {
    // .title-list-item {
    // font-weight: 700;
    //
    // line-height: 1.235;
    // text-transform: uppercase;
    // margin: 3px 0px 0px 10px;
    // letter-spacing: 0.4px;
    // font-size: 14px;
    // color: rgb(47, 47, 47);
    // position: sticky;
    // top: -1rem;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    // padding-left: 15px;
    // background-color: rgb(255, 255, 255);
    // z-index: 2;
    // }
    // }
    .title-params-config {
      // padding: 12px 0px 0px 0px;
      margin-top: 8px;
    }
    // .border-top-solid {
    //   border-top: 1px solid;
    //   border-color: #d8d8d8 !important;
    // }
    // .border-bottom-solid {
    //   padding: 4px 0 !important;
    // }
    .menu-crm::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    .menu-crm::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .menu-crm::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #d8d8d8;
      cursor: pointer !important;
    }
    .menu-crm::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
  .content {
    display: block;
    // padding: 0 9px;
    height: 100%;
    margin-left: 260px;
    overflow-y: hidden;
    box-sizing: border-box;
    background-color: #fff;
    transition: 0.5s all ease;
    transition-timing-function: ease-out;
    &.hidden {
      transform: translateX(0px);
      transition: all 0.5s ease 0s;
      transition-timing-function: ease-out;
      margin-left: 60px;
    }
    .content-global-crm {
      height: 100%;
      .header-content-global-crm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d8d8d8;
        flex-wrap: nowrap;
        overflow-y: hidden;
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .menu-crm-web {
          display: flex;
          align-items: center;
          height: 73px;
          align-items: center;
          .menu-item {
            height: 37px;
            border-radius: 3px;
            background-color: rgba(246, 246, 246, 1);
            width: 100%;
            padding: 5px 7px 5px 7px;
            border: 1px solid rgba(229, 229, 229, 1);
            color: rgba(47, 47, 47, 1);
            margin: 3px;
            font-weight: 700;

            font-size: 14px;
            white-space: nowrap;
            cursor: pointer;
            a {
              display: flex;
              align-items: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-decoration: none;
              color: rgba(47, 47, 47, 1);
              &.router-link-exact-active {
                &.router-link-active {
                  color: #fff !important;
                }
              }
            }
            &.active {
              background-color: rgba(58, 22, 127, 1);
              color: #fff;
              .color-title {
                color: #fff !important;
              }
              .icon-flech-menu {
                &:hover {
                  border-radius: 20px;
                  background-color: #f6f6f6;
                  color: #3a167f;
                }
              }
              .icon-flech {
                color: #fff;
                &:hover {
                  color: #3a167f;
                }
              }
              .count {
                background-color: #fff;
                color: #3a167f;
              }
            }
            .count {
              height: 23px;
              padding: 4px 6px 4px 6px;
              background-color: #3a167f;
              color: #fff;
              font-size: 11px;
              border-radius: 4px;
            }
            .icon-flech-menu {
              &:hover {
                border-radius: 20px;
                color: #3a167f;
                background-color: #fff;
              }
            }
            .color-active {
              color: #fff !important;
            }
          }
        }

        .config {
          background-color: #ffffff;
          border-radius: 5px;
          border: 1px solid;
          padding: 2px 2px 2px 2px;
          color: #3a167f;
          &.solid-color {
            background-color: #3a167f;
            color: #ffffff;
            .color-icon {
              color: #ffffff;
              background-color: #3a167f;
              font-size: 27px !important;
            }
            &:hover {
              background-color: #ffffff;
              color: #3a167f;
              .color-icon {
                color: #3a167f;
                background-color: #ffffff;
              }
            }
          }
          .title-setting {
            display: none;
          }
          .color-icon {
            color: #3a167f;
            font-size: 27px !important;
          }
          &:hover {
            background-color: #3a167f;
            .color-icon {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .sz-loader-icon {
    &.color {
      color: #fff !important;
    }
  }
  .title-cdg {
    font-weight: 700;

    font-size: 16px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    padding: 0px;
    margin: 0px;
  }
}

//  END Style Affichage Demo
.page-content-accueil {
  position: relative;
  // padding: 16px 0px 16px 16px;
  padding: 0px;
  // height: 100%;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  .menu-content {
    display: block;
    position: absolute;
    width: 325px;
    height: 94%;
    padding: 0px;
    transform: translateX(0px);
    transition: 0.5s all ease;
    transition-timing-function: ease-out;
    &.hidden {
      width: 100px;
      // border-right: 1px solid rgb(216, 216, 216);
      height: calc(100% - 64px);
      position: fixed;
      transition: all 0.3s ease 0s;
    }
  }
  .bold-title {
    font-weight: 700;

    font-size: 16px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    padding: 0px;
    margin: 0px;
    margin-top: 2px;
    margin-block-end: 5px;
  }
  .title-item-nav-footer {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;

    .v-list-item__icon {
      -ms-flex-item-align: start;
      align-self: flex-start;
      margin: 10px 14px;
      min-width: 19px;
      height: 25px;
    }
    // .v-list-item__icon:first-child {
    //   margin-right: 20px;
    // }
  }
  // .margin-title {
  //   margin-top: -5px;
  // }
  .margin-title-config {
    margin-top: 5px;
  }
  .nav-menu-crm {
    overflow: hidden !important;
    width: 325px !important;
    .menu-crm {
      // display: flex;
      // justify-content: center;
      // flex-direction: column;
      // flex: 1 1 100px;
      // padding-left: 1rem;
      // // padding-top: 2rem;
      // padding-bottom: 1rem;
      // padding-right: 2rem;
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      -webkit-box-flex: 1;
      flex: 1 1 100px;
      padding-left: 2rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
      position: relative;
      overflow-y: auto !important;
      overflow-x: hidden !important;
      flex-wrap: nowrap !important;
      max-height: calc(100vh - 410px) !important;
      height: calc(100vh - 410px) !important;
      &.mini {
        max-height: calc(100vh - 355px) !important;
        height: calc(100vh - 355px) !important;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
    .menu-crm-no-scroll {
      //  display: flex;
      // justify-content: center;
      // flex-direction: column;
      // flex: 1 1 100px;
      // padding-left: 1rem;
      // padding-top: 2rem;
      // padding-bottom: 1rem;
      // padding-right: 2rem;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      padding-left: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 1px solid rgb(229, 229, 229);
      // position: relative;
      // overflow-y: auto !important;
      // overflow-x: hidden !important;
      // flex-wrap: nowrap !important;
      // // padding: 14px 18px;
      // -webkit-box-pack: center;
      // justify-content: center;
      // flex-direction: column;
      // -webkit-box-flex: 1;
      // flex: 1 1 100px;
      // padding-left: 2rem;
      // padding-top: 0rem;
      // padding-bottom: 0rem;
      // position: relative;
      // overflow-y: auto !important;
      // overflow-x: hidden !important;
      // flex-wrap: nowrap !important;
    }
    .menu-crm,
    .menu-params,
    .menu-crm-no-scroll {
      .item-menu {
        margin-bottom: 2px !important;
        min-height: 40px !important;
        display: flex;
        align-items: center;
        white-space: nowrap;
        width: 266px;
        margin-inline-start: -15px;
        padding: 0px 16px !important;
        border-radius: 6px;
        &.mini {
          width: 58px;
        }
        .link {
          a {
            overflow: hidden;
            font-weight: 700 !important;
            font-size: 12px !important;
            line-height: 1.5;
            white-space: nowrap;
            text-decoration: none;
            color: rgba(47, 47, 47, 1);

            letter-spacing: 0.4px;
            &.router-link-exact-active {
              &.router-link-active {
                color: rgba(47, 47, 47, 1);
                font-weight: 500;
                letter-spacing: 0.4px;
              }
            }
          }
        }
        .v-list-item__icon {
          margin-right: 14px !important;
          gap: 10px;
          height: 27px !important;
        }

        &.active {
          background: rgba(92, 45, 211, 0.1);
          color: rgb(92, 45, 211);
          border-radius: 6px;
          width: 266px;
          margin-inline-start: -15px;
          &.mini {
            width: 58px;
          }
          .color-image {
            filter: brightness(0) saturate(100%) invert(28%) sepia(43%)
              saturate(3767%) hue-rotate(245deg) brightness(89%) contrast(83%);
          }
          .color-icon-current {
            color: #5c2dd3 !important;
          }
          .text {
            font-weight: 700 !important;
            font-size: 12px !important;
            color: #5c2dd3 !important;
            line-height: 1.5 !important;

            letter-spacing: 0.4px;
          }

          .link {
            a {
              background: rgba(92, 45, 211, 0.1);
              color: rgb(92, 45, 211);
              border-radius: 6px;
              overflow: hidden;
              font-weight: 700;
              font-size: 12px;
              line-height: 19.5px;
              white-space: nowrap;
              text-decoration: none;
              // color: #5C2DD3 !important;

              letter-spacing: 0.4px;
              &.router-link-exact-active {
                &.router-link-active {
                  color: #5c2dd3 !important;
                  font-weight: 700;
                  letter-spacing: 0.4px;
                  font-size: 12px;
                }
              }
            }
          }
        }
        &:hover {
          background: rgba(92, 45, 211, 0.1) !important;
          color: rgb(92, 45, 211) !important;
          border-radius: 6px !important;
          .color-image {
            filter: brightness(0) saturate(100%) invert(28%) sepia(43%)
              saturate(3767%) hue-rotate(245deg) brightness(89%) contrast(83%);
          }
          .color-icon-current {
            color: rgb(92, 45, 211) !important;
            font-size: 12px;

            letter-spacing: 0.4px;
          }
          .text {
            color: rgb(92, 45, 211) !important;
            font-weight: 700 !important;
            font-size: 12px !important;

            letter-spacing: 0.4px;
          }
          .v-list-item__icon {
            color: rgb(92, 45, 211) !important;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
    }
    .title-h4-1 {
      font-weight: 700;

      line-height: 1.235;
      text-transform: uppercase;
      margin: 0px 0px 1px -15px;
      letter-spacing: 0.4px;
      font-size: 14px;
      color: rgb(47, 47, 47);
      position: sticky;
      top: 0rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 15px;
      background-color: #ffffff;
      z-index: 2;
    }
    .title-h4-2 {
      font-weight: 700;

      line-height: 1.235;
      text-transform: uppercase;
      margin: 0px 0px 18px -15px;
      letter-spacing: 0.4px;
      font-size: 14px;
      color: rgb(47, 47, 47);
      position: sticky;
      top: 0rem;
      // padding-top: 1rem;
      // padding-bottom: 1rem;
      padding-left: 15px;
      background-color: #ffffff;
      z-index: 2;
    }
    // .params {
    // .title-list-item {
    // font-weight: 700;
    //
    // line-height: 1.235;
    // text-transform: uppercase;
    // margin: 3px 0px 0px 10px;
    // letter-spacing: 0.4px;
    // font-size: 14px;
    // color: rgb(47, 47, 47);
    // position: sticky;
    // top: -1rem;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    // padding-left: 15px;
    // background-color: rgb(255, 255, 255);
    // z-index: 2;
    // }
    // }
    .title-params-config {
      // padding: 12px 0px 0px 0px;
      margin-top: 8px;
    }
    // .border-top-solid {
    //   border-top: 1px solid;
    //   border-color: #d8d8d8 !important;
    // }
    // .border-bottom-solid {
    //   padding: 4px 0 !important;
    // }
    .menu-crm::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    .menu-crm::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .menu-crm::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #d8d8d8;
      cursor: pointer !important;
    }
    .menu-crm::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
  .content {
    display: block;
    // padding: 0 9px;
    height: 100%;
    margin-left: 325px;
    overflow-y: hidden;
    box-sizing: border-box;
    background-color: #fff;
    transition: 0.5s all ease;
    transition-timing-function: ease-out;
    &.hidden {
      transform: translateX(0px);
      transition: all 0.5s ease 0s;
      transition-timing-function: ease-out;
      margin-left: 100px;
    }
    .content-global-crm {
      height: 100%;
      .header-content-global-crm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d8d8d8;
        flex-wrap: nowrap;
        overflow-y: hidden;
        flex-wrap: nowrap;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .menu-crm-web {
          display: flex;
          align-items: center;
          height: 73px;
          align-items: center;
          .menu-item {
            height: 37px;
            border-radius: 3px;
            background-color: rgba(246, 246, 246, 1);
            width: 100%;
            padding: 5px 7px 5px 7px;
            border: 1px solid rgba(229, 229, 229, 1);
            color: rgba(47, 47, 47, 1);
            margin: 3px;
            font-weight: 700;

            font-size: 14px;
            white-space: nowrap;
            cursor: pointer;
            a {
              display: flex;
              align-items: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-decoration: none;
              color: rgba(47, 47, 47, 1);
              &.router-link-exact-active {
                &.router-link-active {
                  color: #fff !important;
                }
              }
            }
            &.active {
              background-color: rgba(58, 22, 127, 1);
              color: #fff;
              .color-title {
                color: #fff !important;
              }
              .icon-flech-menu {
                &:hover {
                  border-radius: 20px;
                  background-color: #f6f6f6;
                  color: #3a167f;
                }
              }
              .icon-flech {
                color: #fff;
                &:hover {
                  color: #3a167f;
                }
              }
              .count {
                background-color: #fff;
                color: #3a167f;
              }
            }
            .count {
              height: 23px;
              padding: 4px 6px 4px 6px;
              background-color: #3a167f;
              color: #fff;
              font-size: 11px;
              border-radius: 4px;
            }
            .icon-flech-menu {
              &:hover {
                border-radius: 20px;
                color: #3a167f;
                background-color: #fff;
              }
            }
            .color-active {
              color: #fff !important;
            }
          }
        }

        .config {
          background-color: #ffffff;
          border-radius: 5px;
          border: 1px solid;
          padding: 2px 2px 2px 2px;
          color: #3a167f;
          &.solid-color {
            background-color: #3a167f;
            color: #ffffff;
            .color-icon {
              color: #ffffff;
              background-color: #3a167f;
              font-size: 27px !important;
            }
            &:hover {
              background-color: #ffffff;
              color: #3a167f;
              .color-icon {
                color: #3a167f;
                background-color: #ffffff;
              }
            }
          }
          .title-setting {
            display: none;
          }
          .color-icon {
            color: #3a167f;
            font-size: 27px !important;
          }
          &:hover {
            background-color: #3a167f;
            .color-icon {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .sz-loader-icon {
    &.color {
      color: #fff !important;
    }
  }
  .title-cdg {
    font-weight: 700;

    font-size: 16px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    padding: 0px;
    margin: 0px;
  }
}
</style>
<style lang="scss">
.page-content-accueil {
  .header-content-global-crm {
    .v-input__slot {
      min-height: 25px !important;
    }
    .v-input__icon {
      height: 20px !important;
    }
    .v-icon.v-icon {
      font-size: 20px !important;
    }
  }
  .nav-menu-crm {
    .v-navigation-drawer__content {
      overflow: hidden !important;
    }
  }
  .margin-start-title {
    padding: 0px;
    &.mini {
      padding: 0px 20px;
    }
    .block-icon {
      padding: 10px 0px;
      margin: 0px;
    }

    .avatar-icon {
      // width: 47px !important;
      height: 40px;
      min-width: 40px;
      width: 46px !important;

      .mini {
        font-size: 35px !important;
        height: 35px !important;
      }
    }
    .title-crm {
      padding: 1rem 0px;
      margin: 0px;
      // border-bottom: 1px solid rgb(229, 229, 229);
      background: rgb(255, 255, 255);
      display: flex;
      z-index: 1;
      width: 324px;
      transition: all 0.5s ease 0s;
      flex: 0 0 auto;
      -webkit-box-align: center;
      align-items: center;
      padding-inline-start: 2rem;
    }
    .margin-title {
      padding-inline-start: 2rem;
    }
  }
  // .v-list-item__content {
  //   // padding: 3px 0 !important;
  // }
  .v-list-item {
    min-height: 35px !important;
  }
  .theme--light.v-list-item:hover::before {
    opacity: 0 !important;
  }

  .theme--light.v-list-item:focus::before {
    opacity: 0 !important;
  }
}
.list-type-projet-item {
  min-height: 35px !important;
  &.active {
    background-color: #f6f6f6 !important;
  }
}
.list-type-projet-scroll {
  height: max-content;
  overflow: auto;
  max-height: 80vh;
}
.list-type-projet-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.list-type-projet-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 4px;
  background-color: rgba(238, 238, 238, 1) !important;
}
.list-type-projet-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(189, 189, 189, 1);
  cursor: pointer !important;
}
.list-type-onglet-projet-accueil {
  .count {
    text-align: center;
    height: 23px;
    padding: 5px 6px 4px 6px;
    background-color: #3a167f !important;
    min-width: 50%;
    max-width: 100%;
    display: inline-block;
    color: #fff !important;
    font-size: 11px !important;
    border-radius: 4px !important;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title-type-popover {
    max-width: 190px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.tootltip-home {
  // background: rgba(112, 74, 209, 0.1) !important;
  background-color: #fff !important;
  color: #5c2dd3 !important;
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  line-height: 22px;
  display: inline-block;
  padding: 2px 11px;
  position: absolute;
  text-transform: initial;
  width: auto;
  opacity: 0;
  pointer-events: none;
  &:before {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #5c2dd3;
    left: -8px;
    top: 4px;
  }

  &:after {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fcfcfc;
    left: -8px;
    top: 4px;
  }
}
.tootltip-home.menuable__content__active {
  opacity: 1;
}
</style>
