<template>
  <div class="overlay-modal" ref="modal">
    <portal to="new-project-modal">
      <ModalRightDynamic @closeModal="handleClose('openModal')">
        <template #title>
          <div class="font-sz-18 font-text bold-700 text-capitalize">
            <span v-if="typeCrud == $t('btnAdd')"> {{ typeCrud }} </span
            >{{ labelModal }}
            <span v-if="typeCrud == $t('update')">{{
              $i18n.locale === 'fr' ? 'Profile' : 'Profil'
            }}</span>
          </div>
        </template>
        <template #content-form>
          <v-form ref="openModal" v-if="formDefault">
            <v-text-field
              v-if="typeCrud == $t('update')"
              :label="$t('contact_Id')"
              dense
              :persistent-placeholder="true"
              outlined
              disabled
              color="#5C2DD3"
              v-model="formDefault.contact_id"
              item-color="#5C2DD3"
            >
            </v-text-field>
            <v-autocomplete
              :label="$t('civility')"
              dense
              :placeholder="$t('searchMsg', { msg: $t('civility') })"
              v-model="formDefault.civility"
              return-object
              :no-data-text="$t('noDataOptionMsg', { option: $t('civility') })"
              item-text="name"
              item-value="id"
              :items="[
                { id: 'Mr', name: 'Mr' },
                { id: 'Mme', name: 'Mme' },
                { id: 'Mlle', name: 'Mlle' }
              ]"
              outlined
              :persistent-placeholder="true"
              class="msg-error mt-2"
              :menu-props="{ bottom: true, offsetY: true }"
              color="#5C2DD3"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('civility') + ' ' + $t('msgOblg')]"
            ></v-autocomplete>
            <v-text-field
              :label="$t('full_name')"
              dense
              :persistent-placeholder="true"
              outlined
              color="#5C2DD3"
              v-model="formDefault.full_name"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('full_name') + ' ' + $t('msgOblg')]"
            >
            </v-text-field>
            <v-autocomplete
              :label="$t('lead_owner')"
              dense
              :placeholder="$t('searchMsg', { msg: $t('lead_owner') })"
              v-model="formDefault.lead_owner"
              return-object
              :no-data-text="
                $t('noDataOptionMsg', { option: $t('lead_owner') })
              "
              item-text="full_name"
              item-value="id"
              :items="allUsers"
              outlined
              :persistent-placeholder="true"
              class="msg-error mt-2"
              :menu-props="{ bottom: true, offsetY: true }"
              color="#5C2DD3"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('lead_owner') + ' ' + $t('msgOblg')]"
            ></v-autocomplete>
            <v-text-field
              :label="$t('phone_number')"
              dense
              :persistent-placeholder="true"
              outlined
              color="#5C2DD3"
              v-model="formDefault.phone_number"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('phone_number') + ' ' + $t('msgOblg')]"
            >
            </v-text-field>
            <v-text-field
              :label="$t('email_address')"
              dense
              :persistent-placeholder="true"
              outlined
              color="#5C2DD3"
              v-model="formDefault.email_adress"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[
                v => !!v || $t('email_address') + ' ' + $t('msgOblg'),
                v => /.+@.+\..+/.test(v) || $t('msgValidEmail')
              ]"
            >
            </v-text-field>
            <v-autocomplete
              :label="$t('job')"
              dense
              :placeholder="$t('searchMsg', { msg: $t('job') })"
              v-model="formDefault.job_title"
              return-object
              :no-data-text="$t('noDataOptionMsg', { option: $t('job') })"
              item-text="name"
              item-value="id"
              :items="getContactJob"
              :loading="getContactJobProcessing"
              outlined
              :persistent-placeholder="true"
              class="msg-error mt-2"
              :menu-props="{ bottom: true, offsetY: true }"
              color="#5C2DD3"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('job') + ' ' + $t('msgOblg')]"
            ></v-autocomplete>
            <v-autocomplete
              :label="$t('nationality')"
              dense
              :placeholder="$t('searchMsg', { msg: $t('nationality') })"
              v-model="formDefault.nationality"
              return-object
              :no-data-text="
                $t('noDataOptionMsg', { option: $t('nationality') })
              "
              item-text="name"
              item-value="id"
              :items="getContactPays"
              :loading="getContactsPaysProcessing"
              outlined
              :persistent-placeholder="true"
              class="msg-error mt-2"
              :menu-props="{ bottom: true, offsetY: true }"
              color="#5C2DD3"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('nationality') + ' ' + $t('msgOblg')]"
            ></v-autocomplete>
            <v-autocomplete
              :label="$t('location')"
              dense
              :placeholder="$t('searchMsg', { msg: $t('location') })"
              v-model="formDefault.location"
              return-object
              :no-data-text="$t('noDataOptionMsg', { option: $t('location') })"
              item-text="name"
              item-value="id"
              :items="getContactPays"
              :loading="getContactsPaysProcessing"
              outlined
              :persistent-placeholder="true"
              class="msg-error mt-2"
              :menu-props="{ bottom: true, offsetY: true }"
              color="#5C2DD3"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('location') + ' ' + $t('msgOblg')]"
            ></v-autocomplete> </v-form
        ></template>
        <template #content-message>
          <div class="message">
            <div
              v-if="
                getCustomContactsProcessing &&
                  (!errorMessages || !errorMessages.length)
              "
              class="loading"
            >
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>
        </template>
        <template #content-actions>
          <v-btn
            color="#45148F"
            dark
            @click.prevent.stop="handlValidation"
            :loading="
              getCustomContactsProcessing &&
                (!errorMessages || !errorMessages.length)
            "
          >
            <span class="title-btn-edit-info text-capitalize">
              {{ typeCrud }}
              {{ labelModal }}</span
            >
            <!-- <span><v-icon>mdi-chevron-right</v-icon></span> -->
          </v-btn>
          <v-btn
            text
            class="ml-3"
            @click.prevent.stop="handleClose('openModal')"
            variant="outline-secondary"
          >
            <span
              class="font-text bold-600 font-sz-12 color-black text-capitalize"
            >
              {{ $t('btnCancel') }}</span
            >
          </v-btn></template
        >
      </ModalRightDynamic>
    </portal>
  </div>
</template>
<script>
import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'modal-contact-immo',
  props: {
    data: { required: true },
    labelModal: { required: true },
    typeCrud: { required: true }
  },
  components: {
    ModalRightDynamic
  },
  data() {
    return {
      errorMessages: null,
      objetDefault: {
        full_name: null,
        lead_owner: null,
        email_adress: null,
        phone_number: null,
        nationality: null,
        location: null,
        job_title: null,
        civility: null
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchListContactPays',
      'fetchListContactJob',
      'getAllUsers'
    ]),

    handleClose() {
      // this.$refs.openModal.resetValidation()
      this.$emit('close')
    },
    handlValidation() {
      let body = {
        full_name: this.formDefault.full_name,
        lead_owner:
          this.formDefault.lead_owner && this.formDefault.lead_owner.id
            ? this.formDefault.lead_owner.id
            : this.formDefault.lead_owner,
        email_adress: this.formDefault.email_adress,
        phone_number: this.formDefault.phone_number,
        nationality:
          this.formDefault.nationality && this.formDefault.nationality.id
            ? this.formDefault.nationality.id
            : this.formDefault.nationality,
        location:
          this.formDefault.location && this.formDefault.location.id
            ? this.formDefault.location.id
            : this.formDefault.location,
        job_title:
          this.formDefault.job_title && this.formDefault.job_title.id
            ? this.formDefault.job_title.id
            : this.formDefault.job_title,
        civility:
          this.formDefault.civility && this.formDefault.civility.id
            ? this.formDefault.civility.id
            : this.formDefault.civility
      }
      if (this.$refs.openModal.validate()) {
        this.$emit('valider', body, this.formDefault)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCustomContactsProcessing',
      'getCustomContactsError',
      'getContactPays',
      'getContactsPaysProcessing',
      'getContactJob',
      'getContactJobProcessing',
      'allUsers'
    ]),
    formDefault: function() {
      let objet = this.data ? this.data : this.objetDefault
      return objet
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getCustomContactsError(newValue) {
      this.errorMessages = newValue
    }
  },
  mounted() {
    this.getAllUsers()
    this.fetchListContactPays()
    this.fetchListContactJob()
  }
}
</script>
